<!-- Navbar -->
<div *ngIf="isLoggedIn">
  <mdb-navbar SideClass="navbar navbar-toggleable-md navbar-expand-lg double-nav" id="halcyon-navbar" [containerInside]="false">
    <mdb-navbar-brand class="navbar-brand ml-3">
      <img class="mr-3" src="assets/n27/logo-small.svg" width="40">
      <strong>North27 School Application | Halcyon School</strong>
    </mdb-navbar-brand>
    <div class="collapse navbar-collapse" id="mainnav">
      <ul class="navbar-nav ml-auto nav-flex-icons">
        <li class="nav-item">
          <span class="nav-link">
            {{ currentUser.email }}
          </span>
        </li>
        <li class="nav-item avatar dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            <img src="{{ currentUser.photoURL }}" alt="{{ currentUser.photoURL }}" class="img-fluid rounded-circle z-depth-0">
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect (click)="authService.signOut()">Log out</a>
          </div>
        </li>
      </ul>
    </div>
  </mdb-navbar>
  <mdb-navbar SideClass="navbar navbar-light white navbar-toggleable-md navbar-expand-lg double-nav" id="halcyon-navbar-2" [containerInside]="false">
    <!-- <mdb-navbar-brand class="navbar-brand ml-3">
      <img class="mr-3" src="assets/n27/logo-small.svg" width="40">
      <strong>North27 School Application | Halcyon School</strong>
    </mdb-navbar-brand> -->
    
    <!-- Collapsible content -->
    <mdb-navbar-brand>
  <links>
    <!-- Links -->
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/analytics">Analytics</a>
      </li>
      <!-- Dropdown -->
      <li class="nav-item dropdown" routerLinkActive="active" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Posts<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/view-posts">View</a>
          <a [ngClass]="{'disabled': currentUser.role === 0}" class="dropdown-item waves-light" mdbWavesEffect routerLink="/add-post">Add</a>
        </div>
      </li>
      <li class="nav-item dropdown" routerLinkActive="active" dropdown>
        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          Notifications<span class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" mdbWavesEffect routerLink="/view-notifications">View</a>
          <a [ngClass]="{'disabled': currentUser.role === 0}" class="dropdown-item waves-light" mdbWavesEffect routerLink="/notification">Send</a>
        </div>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/re-registrations">Re-registration</a>
      </li>
      <li *ngIf="currentUser.role == 1" class="nav-item" routerLinkActive="active">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/view-scans">Sign in/out Log</a>
      </li>
      <!-- <li class="nav-item" routerLinkActive="active">
        <a class="nav-link waves-light" mdbWavesEffect routerLink="/who-is-in">Who is in</a>
      </li> -->
    </ul>
    </links>
    <!-- Links -->
  </mdb-navbar-brand>
  <div class="collapse navbar-collapse" id="mainnav">
    <ul class="navbar-nav ml-auto nav-flex-icons">
      <li class="nav-item">
        <span class="nav-link">
          Last synced iSAMS @&nbsp;{{ isamsSyncTime | date: 'medium' }}
        </span>
      </li>
    </ul>
  </div>
  </mdb-navbar>
  <!-- <div class="row mt-4 p-3 bg-white border-bottom shadow-sm">
  <div class="col-3">
    <mdb-select [options]="navigationOptions" [placeholder]="selectedNavigationOption" label="Navigate" class="colorful-select dropdown-primary" (selected)="changeNavigation($event)"></mdb-select>
  </div>
</div> -->
  <!-- <div
    class="secondary-menu d-flex flex-column flex-md-row align-items-center p-3 bg-white border-bottom shadow-sm">
    <a routerLink="/dashboard" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Dashboard</a>
    <a routerLink="/view-posts" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Posts</a>
    <a routerLink="/add-post" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Add Post</a>
    <a routerLink="/view-notifications" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Notifications</a>
    <a routerLink="/notification" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Send Notification</a>
    <a *ngIf="currentUser.role == 1" routerLink="/view-scans" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Sign in/out Log</a>
    <a routerLink="/re-registrations" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Re-registrations</a>
    <a routerLink="/analytics" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Analytics</a>
    <a routerLink="/who-is-in" routerLinkActive="active-button" class="menu-button btn btn-outline-primary mr-2">Who is in</a>
  </div> -->
  <ng-progress id="myProgress" [spinner]="false" [meteor]="false" [color]="'red'"></ng-progress>
</div>
<div class="mb-3"></div>
<!-- ./Navbar -->

<!-- Loader -->
<!-- <div *ngIf="!authService.userData" class="browser-screen-loading-content">
  <div class="loading-dots dark-gray">
    <i></i>
    <i></i>
    <i></i>
    <i></i>
  </div>
</div> -->
<!-- ./Loader -->
import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { Notification } from '../../interfaces/notification';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private firestore: AngularFirestore,
    private httpClient: HttpClient
  ) { }

  getNotification(id) {
    return this.firestore.collection('notifications').doc(id).snapshotChanges();
  }

  getNotifications() {
    return this.firestore.collection('notifications', ref => ref.orderBy('dateCreated', 'desc')).snapshotChanges();
  }

  getDevices() {
    return this.firestore.collection('devices', ref => ref.orderBy('dateCreated', 'desc')).snapshotChanges();
  }

  updateBadge(id: string, badge: number) {
    return new Promise<any>((resolve, reject) => {
      this.firestore.collection('devices').doc(id).update({ badge: badge, dateUpdated: new Date() })
      .then (response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  insertNotification(notification: Notification) {
    return this.firestore.collection('notifications').add(notification);
  }

  sendNotificationNotSendEmail(emails: Array<string>, title: string, text: string) {
    const url = 'https://europe-west2-school-app-27022019.cloudfunctions.net/notificationNotSentEmail';
    const params = {
      user: JSON.parse(localStorage.getItem('user')),
      emails: emails,
      title: title,
      text: text
    };
    return this.httpClient.post(url, params);
  }

  deleteNotification(notificationId: string) {
    this.firestore.doc('notifications/' + notificationId).delete();
  }
}

import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  folder;
  public uploadProgress = new BehaviorSubject(0);

  constructor(private datePipe: DatePipe) {
    var date = new Date();
    this.folder = 'public/' + this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  doAsyncTask() {
    let promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('Async Work Complete');
        resolve();
      }, 2000);
    });
    return promise;
  }

  uploadFile(file) {
    const self = this;
    const contentType = file.type;
    const bucket = new S3({
      endpoint: 'https://halcyonschool.ams3.digitaloceanspaces.com',
      region: 'eu-west-2',
      accessKeyId: 'PZQEE7WXBSNP4YE4IWAT',
      secretAccessKey: 'NPWcH5W3hxJXQTBXKrKzTv+vZYVfNWqEa4RqNwAb8kc',
    });
    const params = {
      Bucket: 'App',
      Key: this.folder + '/' + file.name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };

    let promise = new Promise((resolve, reject) => {
      bucket
        .upload(params)
        .on('httpUploadProgress', function (evt) {
          // console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          let percentage = Math.round((evt.loaded * 100) / evt.total);
          self.uploadProgress.next(percentage);
          // console.log("Uploaded " + percentage + '%');
        })
        .send(function (err, data) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            reject(err);
            // return false;
          }
          console.log('Successfully uploaded file.', data);
          resolve(data);
          // return true;
        });
      // bucket.upload(params, function (err, data) {
      //   if (err) {
      //     console.log('There was an error uploading your file: ', err);
      //     reject(err);
      //   } else {
      //     console.log('Successfully uploaded file.', data);
      //     resolve(data);
      //   }
      // });
    });
    self.uploadProgress.next(0);
    return promise;
  }
}

import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

import { PostService } from '../../services/post/post.service'; // Post services API
import { Post } from '../../models/post/post.model';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss'],
})
export class PostListComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;

  elements: any = [];
  headElements = ['title', 'subTitle', 'content', 'dateUpdated', 'featured', 'visibility', 'actions'];

  searchText: string = '';
  previous: string;

  maxVisibleItems: number = 10;
  posts: Post[];
  currentUser: object;

  constructor(
    private postService: PostService,
    private cdRef: ChangeDetectorRef,
    ) {
      this.currentUser = JSON.parse(localStorage.getItem('user'));
    }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {
    this.postService.getPosts().subscribe((data) => {
      this.posts = data.map((e) => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as Post),
        };
      });

      this.elements = this.posts;
      this.mdbTable.setDataSource(this.elements);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
    });
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  delete(id: string) {
    if (
      confirm(
        'Are you sure you want to delete this post? This operation cannot be undone, it is irreversible.'
      )
    ) {
      this.postService.deletePost(id);
    }
  }

  truncateHTML(text: string): string {
    try {
      let charLimit = 50;
      if (!text || text.length <= charLimit) {
        return text;
      }
  
      let withoutHtml = text.replace(/<(?:.|\n)*?>/gm, '');
      let shortened = withoutHtml.substring(0, charLimit) + '...';
      return shortened;
    } catch (error) {
      console.error('Error in truncating HTML', error);

      return text;
    }
  }
}

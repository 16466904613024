import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { Scan } from '../../models/scan/scan.model';

@Injectable({
  providedIn: 'root'
})
export class ScansService {

  constructor(private firestore: AngularFirestore) { }

  getScans(year: number, month: number) {
    console.log('Year', year, 'Month', month);
    // First day of month
    var firstDay = new Date(year, month, 1);
    console.log('firstDay', firstDay);
    // Last day of month
    var lastDay = new Date(year, month + 1, 0);
    console.log('lastDay', lastDay);
    return this.firestore.collection('inOutLogs', ref => ref
    .orderBy('dateCreated')
    .startAt(firstDay)
    .endAt(lastDay))
    // .limit(100))
    .snapshotChanges();
  }
}

import { Component, OnInit } from '@angular/core';

import { Scan } from '../../models/scan/scan.model';
import { Isams } from '../../models/isams/isams.model';
import { ScansService } from '../../services/scans/scans.service';
import { IsamsService } from '../../services/isams/isams.service';
import { from, Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-scan-list',
  templateUrl: './scan-list.component.html',
  styleUrls: ['./scan-list.component.scss']
})
export class ScanListComponent implements OnInit {

  public scans = new BehaviorSubject<Scan[]>([]);
  public scansDataArray;
  private isamsData = new Subject<Isams[]>();
  private isamsDataArray;
  private months: Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  private currentMonth: number = new Date().getMonth();
  public visibleMonths: Array<string>;
  public selectedMonth: number;
  public years: Array<number>;
  private currentYear: number = new Date().getFullYear();
  public selectedYear: number;

  constructor(
    private scansService: ScansService,
    private isamsService: IsamsService
  ) {
  }

  ngOnInit() {
    this.selectedYear = this.currentYear;
    this.selectedMonth = this.currentMonth;
    this.generateYears(2020);
    this.generateVisibleMonths();
    this.getIsamsData();
  }

  private generateYears(startYear: number) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 2020;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    this.years = years;
  }

  private generateVisibleMonths() {
    this.visibleMonths = this.months.slice(0, (this.currentMonth + 1));
  }

  private generateAllMonths() {
    this.visibleMonths = this.months;
  }

  public switchLog(year: number, month: number) {
    if (year !== null) {
      this.selectedYear = this.years[year];
      if (this.selectedYear < this.currentYear) {
        // Past Year, showing all months
        this.generateAllMonths();
      } else {
        // Current Year
        if (this.selectedMonth > this.currentMonth) {
          // Switching to current month
          this.selectedMonth = this.currentMonth;
        }
        this.generateVisibleMonths();
      }
    }

    if (month !== null) {
      this.selectedMonth = month;
    }

    this.getScanData();
  }

  private getIsamsData() {
    this.isamsService.getIsamsSync().subscribe(data => {
      this.isamsData.next(data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Isams
        }
      }));
    });

    this.isamsData.subscribe((data) => {
      this.isamsDataArray = data;
    });

    this.getScanData();
  }

  private getScanData() {
    this.scansService.getScans(this.selectedYear, this.selectedMonth).subscribe(data => {
      const newEntries = [];
      data.map(e => {
        const email = e.payload.doc.get('email');
        if (email) {
          const isamsData = this.find(email);
          const lateness = (email.includes('@halcyonschool.com') && (e.payload.doc.get('status') == 'in')) ? this.latenessCheck(e.payload.doc.get('dateCreated')) : 'na';
          const tmp = {
            id: e.payload.doc.id,
            name: (isamsData) ? isamsData.preferredName + ' ' + isamsData.surname : 'NA',
            userType: (isamsData) ? isamsData.type : 'NA',
            lateness: lateness,
            ...e.payload.doc.data() as Scan
          }
          newEntries.push(tmp);
        }
      });
      this.scans.next(newEntries);
    });

    // Created ONLY for MDB sorting
    this.scans.subscribe((data) => {
      this.scansDataArray = data;
    });
  }

  private find(email: string) {
    const match: any = this.isamsDataArray.find(element => element.schoolEmailAddress == email);

    if (match) {
      return match;
    } else {
      return null;
    }
  }

  private latenessCheck(date: any) {
    const startTime = date.toDate();
    const startHour = startTime.getHours();
    const startMinute = startTime.getMinutes();

    if ((startHour == 8 && startMinute >= 45) || (startHour == 9 && startMinute <= 45)) {
      return true;
    } else {
      return false;
    }
  }

  public copyTableBody() {
    // @TODO
  }

  //  public findInIsams(email: string, parameter: string) {
  //    console.log('Hi from find');
  //    const match: any = this.isamsDataArray.find(element => element.schoolEmailAddress == email);
  //    console.log(match);
  //    if (match) {
  //      if (parameter == 'name')  {
  //        return match.preferredName + ' ' + match.surname;
  //      } else if (parameter == 'type') {
  //        return match.type;
  //      }
  //    } else {
  //      return 'NA';
  //    }
  //  }

}

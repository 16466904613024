import { Injectable, NgZone } from '@angular/core';

import { User } from '../../interfaces/user';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: object;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private firestore: AngularFirestore
  ) {
    /* Saving user data in session storage when 
    logged in and setting up null when logged out */
    // this.afAuth.authState.subscribe(user => {
    // console.log('user', user);
    // if (user) {
    //   this.userData = user;
    //   localStorage.setItem('user', JSON.stringify(this.userData));
    //   JSON.parse(localStorage.getItem('user'));
    // } else {
    //   localStorage.setItem('user', null);
    //   JSON.parse(localStorage.getItem('user'));
    // }
    // })
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null ? true : false;
  }

  getUsers() {
    try {
      return this.firestore.collection('users').snapshotChanges();
      // Get users list
      // return this.firestore.collection('users').snapshotChanges();
    } catch (error) {}
  }

  // Sign in with Google
  GoogleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  authLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then((result) => {
        const users = [];
        this.firestore
          .collection('users')
          .get()
          .subscribe((data) => {
            data.docs.map((doc) => {
              const tmp = {
                id: doc.id,
                ...(doc.data() as any),
              };

              users.push(tmp);
            });

            if (users?.length > 0) {
              // Check user is valid
              const loggedInUser = users.find(user => user.email === result.user.email);
              if (
                result.user &&
                loggedInUser
              ) {
                let user = {
                  uid: result.user.uid,
                  email: result.user.email,
                  displayName: result.user.displayName,
                  photoURL: result.user.photoURL,
                  // role: this.adminUsers.includes(result.user.email) ? 1 : 0,
                  role: (loggedInUser.admin) ? 1 : 0
                };
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user'));
                this.ngZone.run(() => {
                  this.router.navigate(['analytics']);
                });
                this.setUserData(user);
              } else {
                localStorage.setItem('user', null);
                window.alert('Access Denied');
                JSON.parse(localStorage.getItem('user'));
              }
            }
          });
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  /* Setting up user data when sign in with social auth  
provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      role: user.role,
    };

    return userRef.set(userData, {
      merge: true,
    });
  }

  // Sign out
  signOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'; // Reactive form services
import { NotificationService } from '../../services/notification/notification.service';
import { Notification } from '../../interfaces/notification';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { GsuiteService } from '../../services/gsuite/gsuite.service';
import { GsheetService } from '../../services/gsheet/gsheet.service';
import { PostService } from '../../services/post/post.service';
import { YearGroupsService } from 'src/app/services/year-groups/year-groups.service';
import { IsamsService } from 'src/app/services/isams/isams.service';
import { IsamsYearGroupsComponent } from 'src/app/modals/isams-year-groups/isams-year-groups.component';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

// Sendgrid API - N27
// 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
// Sendgrid API - Halcyon
// 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

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  public postForm: FormGroup;
  private recipientsArray: Array<string>;
  private emailsWithoutToken: Array<string>;
  private emailsWithToken: Array<string>;
  private validTokens: Array<object>;
  public selectedRecipientGroups: Array<string> = [];
  public recipientGroupsOptions: Array<object> = [];
  private selectedRecipientGroupEmails: any = [];
  public recipientMembersOptions: Array<object> = [];
  private selectedMemberGroupEmails: any = [];
  public recipientMembersMessage: string;
  @ViewChild('recipients') recipientsTextArea: ElementRef;
  private automatedNotifications: Array<Array<string>>;
  public automatedNotificationsActive: boolean = false;
  private notificationAutoMode: boolean = false;
  private currentAutomatedNotification: Array<string>;
  public typeOptions: Array<any>;
  public superGroupOptions: Array<any>;
  public appUserGroupOptions: Array<any>;
  public posts: Array<any>;
  public isamsYearGroups: any;
  public isamsUsers: any = [];
  public postOptions: Array<any> = [];
  private filterTokensEnd: boolean;

  constructor(
    public fb: FormBuilder, // Form Builder service for Reactive forms
    private notificationService: NotificationService,
    public toaster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private gsuiteService: GsuiteService,
    private gsheetService: GsheetService,
    private postService: PostService,
    private yearGroupsService: YearGroupsService,
    private isamsService: IsamsService
  ) {}

  ngOnInit() {
    this.typeOptions = [
      { value: '0', label: 'Normal' },
      { value: '1', label: 'Post' },
    ];
    this.superGroupOptions = [
      { value: '0', label: 'Students' },
      { value: '1', label: 'Contacts' },
      { value: '2', label: 'Students & Contacts' },
    ];
    this.appUserGroupOptions = [
      { value: 0, label: 'All Users', selected: true },
      { value: 1, label: 'All Students' },
      { value: 2, label: 'All Parents' },
      { value: 3, label: 'All Staff' },
      { value: 4, label: 'All Consultants' },
      { value: 5, label: 'All Board' },
      { value: 6, label: 'All Guests?' },
    ];
    this.getPosts();
    this.reactivePostForm();
    if (this.activatedRoute.snapshot.params.id) {
      this.getNotification(this.activatedRoute.snapshot.params.id);
    }

    this.getIsamsYearGroups();

    this.getIsamsUsers();

    this.getGroups();

    this.postForm.get('recipientGroups').valueChanges.subscribe((value) => {
      if (value && value.length > 0) {
        // Reset
        this.selectedRecipientGroups = [];

        value.forEach((element) => {
          var test: any = this.recipientGroupsOptions.filter((x: any) => {
            return x.value == element;
          });
          this.selectedRecipientGroups.push(test[0]);
          // Getting Google group members
          // this.getGroupMembers(element);

          // this.getIsamsYearGroupMembers(element);
        });
      } else {
        this.selectedRecipientGroups = [];
        // Resetting recipients
        // this.postForm.controls['recipients'].reset();
      }
    });

    this.postForm.get('recipientMembers').valueChanges.subscribe((value) => {
      if (value) {
        if (this.selectedRecipientGroupEmails['members']) {
          value.forEach((element) => {
            if (
              !this.selectedRecipientGroupEmails['members'].includes(element)
            ) {
              let tmp = this.selectedRecipientGroupEmails['members'].split(';');
              let merged = [...value, tmp];
              // Unique NOT working
              let unique = merged.reduce(
                (unique, item) =>
                  unique.includes(item) ? unique : [...unique, item],
                []
              );
              this.selectedRecipientGroupEmails['members'] = unique.join(';');
            }
          });
        } else {
          this.selectedRecipientGroupEmails['members'] = value.join(';');
        }

        const objectValues = Object.values(this.selectedRecipientGroupEmails);

        this.postForm.controls['recipients'].setValue(objectValues.join(';'));
      }
    });
  }

  private getPosts() {
    this.postService.getPosts().subscribe((data) => {
      this.posts = data.map((e) => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as {}),
        };
      });

      let tmp = new Array();
      this.posts.forEach((post, index) => {
        tmp.push({
          value: index,
          label: post.title + ' - (' + post.visibility + ')',
        });
      });
      this.postOptions = [...this.postOptions, ...tmp];
    });
  }

  private async getIsamsYearGroups() {
    try {
      this.yearGroupsService.getAll().subscribe({
        next: (yearGroups: any) => {
          this.isamsYearGroups = yearGroups.map((e) => {
            return {
              id: e.payload.doc.id,
              ...(e.payload.doc.data() as any),
              // For `mdb-select`
              value: e.payload.doc.data().ncYear,
              label: e.payload.doc.data().name,
            };
          });
          console.log('getIsamsYearGroups', this.isamsYearGroups);
        },

        error: (e) => {
          console.error(
            'Error in getting all year groups from "yearGroupsService"',
            e
          );
        },

        complete() {
          console.log('is completed', this.isamsYearGroups);
        },
      });
    } catch (error) {
      console.error('Error in getting iSAMS year groups', error);
    }
  }

  private getIsamsUsers() {
    try {
      this.isamsUsers.push({
        value: 'tech@halcyonschool.com',
        label: 'Andrew Howden',
      });
      this.isamsService.getIsamsSync().subscribe({
        next: (users: any) => {
          console.log('users', users);
          this.isamsUsers = users.map((e) => {
            const data = e.payload.doc.data();
            // if (data.schoolEmailAddress) {
            // if (data.type === 'staff' && data.schoolId < 100 && data.gender === 'M') {
            return {
              // id: e.payload.doc.id,
              // ...(data as any),
              // For `mdb-select`
              value: e.payload.doc.data().schoolEmailAddress
                ? e.payload.doc.data().schoolEmailAddress
                : e.payload.doc.data().emailAddress,
              label:
                e.payload.doc.data().forename +
                ' ' +
                e.payload.doc.data().surname,
            };

            //   this.isamsUsers.push({
            //     value: "tech@halcyonschool.com",
            //     label: "Andrew Howden"
            // });
            // }
          });
          console.log('this.isamsUsers', this.isamsUsers);
        },

        error: (e) => {
          console.error(
            'Error in getting all year groups from "yearGroupsService"',
            e
          );
        },
      });
    } catch (error) {
      console.error('Error in getting isams users', error);
    }
  }

  private getUsersByYearGroup(yearGroup: string) {
    return new Promise((resolve, reject) => {
      this.yearGroupsService.getByYearGroup(yearGroup).subscribe({
        next: (users: any) => {
          const tmp = users.map((e) => {
            return e.payload.doc.data().schoolEmailAddress;
          });

          resolve(tmp);
        },

        error: (e) => {
          console.error(
            'Error in getting year group members from "yearGroupsService"',
            e
          );
          reject(e);
        },
      });
    });
  }

  public async getIsamsYearGroupMembers() {
    try {
      const groupNcYears = this.postForm.get(['recipientGroups']).value;

      const recipients = [];
      for (const groupNcYear of groupNcYears) {
        console.log('groupNcYear', groupNcYear);
        const users = await this.getUsersByYearGroup(groupNcYear);
        recipients.push(users);
      }

      this.postForm.controls['recipients'].setValue(recipients.join(';'));

      this.resize();
    } catch (error) {
      console.error('Error in getting iSAMS year group members', error);
    }
  }

  resize() {
    let event = new KeyboardEvent('keydown', { code: '40' });
    this.recipientsTextArea.nativeElement.dispatchEvent(event);
  }

  getGroups(nextPageToken: string = null) {
    this.gsuiteService.getGroups(nextPageToken).then((response: any) => {
      if (response.groups) {
        response.groups.forEach((element) => {
          let tmp = {
            value: element.id,
            label:
              element.name + ' - ' + element.directMembersCount + ' Member(s)',
            disabled: element.directMembersCount > 0 ? false : true,
          };
          this.recipientGroupsOptions = [...this.recipientGroupsOptions, tmp];
        });
        if (response.nextPageToken) {
          this.getGroups(response.nextPageToken);
        }
      } else {
        alert('Sorry, no groups found.');
      }
    });
  }

  getGroupMembers(groupId) {
    // Resetting emails
    this.selectedRecipientGroupEmails['groups'] = [];

    this.gsuiteService.getGroupMembers(groupId).then((response: any) => {
      if (response.members) {
        let tmp = new Array();
        response.members.forEach((element) => {
          tmp.push(element.email);
        });

        this.selectedRecipientGroupEmails.groups[groupId] = tmp.join(';');

        const objectValues = Object.values(
          this.selectedRecipientGroupEmails['groups']
        );
        if (this.selectedRecipientGroupEmails['members']) {
          const finalObject = objectValues.concat(
            this.selectedRecipientGroupEmails['members']
          );
          this.postForm.controls['recipients'].setValue(finalObject.join(';'));
        } else {
          this.postForm.controls['recipients'].setValue(objectValues.join(';'));
        }

        this.resize();
      }
    });
  }

  optionChanged(event) {
    // console.log('optionChanged', event);
  }

  debounce(func, wait, immediate = false) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  noMembersFound(event) {
    // this.debounce(this.target, 2500);

    this.gsuiteService
      .getSearchUsers(event)
      .then((response: any) => {
        this.recipientMembersOptions = [];
        if (response.users) {
          response.users.forEach((element) => {
            let tmp = {
              value: element.primaryEmail,
              label: element.name.fullName,
            };
            this.recipientMembersOptions = [
              ...this.recipientMembersOptions,
              tmp,
            ];
          });

          if (this.recipientMembersOptions.length == 1) {
            this.recipientMembersMessage =
              this.recipientMembersOptions.length + ' match found';
          } else {
            this.recipientMembersMessage =
              this.recipientMembersOptions.length + ' matches found';
          }
        } else {
          this.recipientMembersMessage = 'Sorry, no members found';
        }
      })
      .catch((error) => {
        console.error('Error on retrieving search results: ', error);
      });
  }

  recipientMembersClosed(event) {
    this.recipientMembersOptions = [];
  }

  getNotification(id: string) {
    this.notificationService.getNotification(id).subscribe((response) => {
      let notification: any = response.payload.data();
      this.postForm.patchValue({
        type: notification.type ? notification.type : '0',
      });
      this.postForm.patchValue({
        post: notification.post ? notification.post : 0,
      });
      this.postForm.patchValue({ title: notification.title });
      this.postForm.patchValue({ text: notification.text });
      this.postForm.patchValue({ recipients: notification.rawRecipients });
    });
  }

  // Reactive post form
  reactivePostForm() {
    // let emailPattern = "^[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,4})?$";
    // ^\s*([a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,4})?+[;]?+)(;\s*([0-9]+)\s*)*$
    // let emailPattern = "[^;(?!)]+[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,4})?";
    this.postForm = this.fb.group({
      type: ['0', [Validators.required]],
      post: [0],
      title: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
        ],
      ],
      text: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(500),
        ],
      ],
      recipientGroups: [''],
      recipientMembers: [''],
      superGroup: [''],
      appUserGroup: [''],
      recipients: ['', [Validators.required, Validators.minLength(5)]],
    });
  }

  /**
   *
   * @param data Array: Optional input data for automated notifications
   */
  async initNotification(data?: Array<string>) {
    this.filterTokensEnd = false;
    var promise = new Promise(async (resolve, reject) => {
      var recipients;
      if (!this.notificationAutoMode) {
        // Manual mode
        if (this.postForm.invalid) {
          return;
        }
        recipients = this.postForm.controls['recipients'].value;
      } else {
        // Automated
        recipients = this.currentAutomatedNotification[2];
      }

      // Replacing line brakes
      let cleanedRecipients = recipients.replace(/(\r\n|\n|\r)/gm, '');
      // Trimming last item (if necessary)
      if (cleanedRecipients.slice(-1) == ';') {
        cleanedRecipients = cleanedRecipients.slice(0, -1);
      }

      // String to array
      let recipientsArray = cleanedRecipients.split(';');

      // Appending if needed
      recipientsArray.forEach((e: string, i: number, a: Array<string>) => {
        if (e.endsWith('@')) {
          a[i] = e.concat('halcyonschool.com');
        }
      });

      if (!this.notificationAutoMode) {
        let message =
          'Are you sure to send this notification to ' +
          recipientsArray.length +
          ' person(s)?';
        if (!confirm(message)) {
          return;
        }
      }

      this.recipientsArray = recipientsArray;
      // Getting devices
      this.notificationService.getDevices().subscribe(async (response) => {
        let devices = response.map((e) => {
          return {
            dateCreated: new Date(
              e.payload.doc.get('dateCreated').seconds * 1000
            ),
            email: e.payload.doc.get('user.email'),
            token: e.payload.doc.get('token'),
            badge: e.payload.doc.get('badge')
              ? e.payload.doc.get('badge') + 1
              : 1,
            userType: e.payload.doc.get('user.type'),
          };
        });
        if (!this.filterTokensEnd) {
          // Prevent looping
          // console.log('devices', devices)
          await this.removeDuplicates(devices);
          resolve();
        } else {
          // console.log('Endless loop');
        }
      });
    });
    return promise;
  }

  removeDuplicates(array: Array<any>) {
    var promise = new Promise(async (resolve, reject) => {
      var uniqueArray = array.reduce((unique, o) => {
        if (!unique.some((obj) => obj.email === o.email)) {
          unique.push(o);
        }
        return unique;
      }, []);

      await this.filterTokens(uniqueArray);
      resolve();
    });
    return promise;
  }

  filterTokens(data: Array<any>) {
    var promise = new Promise(async (resolve, reject) => {
      var tokens = new Array();
      var noTokenEmails = new Array();
      var tokenEmails = new Array();
      var error = false;
      for (let index = 0; index < this.recipientsArray.length; index++) {
        const e = this.recipientsArray[index];
        const result = data.find((data) => data.email == e);
        if (result) {
          if (this.postForm.value.type == '1') {
            // Post visibility check
            this.postVisibilityCheck(result).catch((e) => {
              error = true;
              alert(
                'Post "visibility" does not match with recipients, please correct this and try again.'
              );
            });
          }
          if (this.postForm.valid) {
            await this.notificationService.updateBadge(
              result.token,
              result.badge
            );
          }

          tokens.push({
            token: result.token,
            badge: result.badge,
          });
          tokenEmails.push(e);
        } else {
          noTokenEmails.push(e);
        }
      }
      if (error) {
        reject('Error in token filtering.');
      } else if (tokens.length != 0 && noTokenEmails.length != 0) {
        this.validTokens = tokens;
        this.emailsWithoutToken = noTokenEmails;
        this.emailsWithToken = tokenEmails;

        await this.insertNotification();
        await this.sendNotificationNotSentEmail(1);
      } else {
        if (tokens.length != 0) {
          this.validTokens = tokens;
          this.emailsWithToken = tokenEmails;
          await this.insertNotification();
        }

        if (noTokenEmails.length != 0) {
          this.emailsWithoutToken = noTokenEmails;
          await this.sendNotificationNotSentEmail();
        }
      }

      if (!error) {
        // Resetting form
        this.postForm.reset({
          type: '0',
          post: 0,
          title: '',
          text: '',
          recipientGroups: '',
          recipientMembers: '',
          recipients: '',
        });

        // Resetting auto mode
        this.notificationAutoMode = false;
        resolve();
      }
    });
    this.filterTokensEnd = true;
    return promise;
  }

  sendNotificationNotSentEmail(type: number = 0) {
    var promise = new Promise(async (resolve, reject) => {
      let title;
      let text;
      if (this.notificationAutoMode) {
        title = this.currentAutomatedNotification[0];
        text = this.currentAutomatedNotification[1];
      } else {
        title = this.postForm.controls['title'].value;
        text = this.postForm.controls['text'].value;
      }

      if (title && text && this.emailsWithoutToken) {
        if (type == 0) {
          await this.insertNotification(false);
        }
        this.notificationService
          .sendNotificationNotSendEmail(this.emailsWithoutToken, title, text)
          .subscribe((response) => {
            this.toaster.success(
              'Successfully emailed non app user(s) details.'
            ); // Show success message when data is successfully submitted
            resolve();
          });
      }
    });
    return promise;
  }

  // Reset form's values
  resetForm() {
    let message = 'Are you sure you want to reset';
    if (this.postForm.controls['title'].value) {
      message = message.concat(
        ': ',
        this.postForm.controls['title'].value + '...'
      );
    } else if (this.postForm.controls['text'].value) {
      message = message.concat(
        ': ',
        this.postForm.controls['text'].value + '...'
      );
    }

    message = message.concat('?');

    if (confirm(message)) {
      this.postForm.reset();
      this.selectedRecipientGroups = [];
    }
  }

  private insertNotification(status: boolean = true) {
    var promise = new Promise(async (resolve, reject) => {
      let type;
      let post;
      let title;
      let text;
      let rawRecipients;
      let initiationType;
      if (this.notificationAutoMode) {
        type = '0';
        post = '';
        title = this.currentAutomatedNotification[0];
        text = this.currentAutomatedNotification[1];
        rawRecipients = this.currentAutomatedNotification[2];
        initiationType = 'automated';
      } else {
        type = this.postForm.controls['type'].value;
        post =
          type == '1'
            ? this.posts[this.postForm.controls['post'].value].id
            : '';
        title = this.postForm.controls['title'].value;
        text = this.postForm.controls['text'].value;
        rawRecipients = this.postForm.controls['recipients'].value;
        initiationType = 'manual';
      }

      if (title && text && rawRecipients) {
        const notification: Notification = {
          type: type,
          post: post,
          title: title,
          text: text,
          tokens: status ? this.validTokens : [],
          initiator: JSON.parse(localStorage.getItem('user')),
          status: status ? 'enqueued' : 'not-sent',
          rawRecipients: rawRecipients,
          initiationType: initiationType,
          appUsers: this.emailsWithToken ? this.emailsWithToken : [],
          nonAppUsers: this.emailsWithoutToken ? this.emailsWithoutToken : [],
          dateCreated: new Date(),
          dateUpdated: new Date(),
        };

        this.notificationService
          .insertNotification(notification)
          .then(() => {
            if (status) {
              this.toaster.success(title + ' successfully enqueued.'); // Show success message when data is successfully submitted

              if (this.notificationAutoMode) {
                let row =
                  Number(
                    this.currentAutomatedNotification[
                      this.currentAutomatedNotification.length - 1
                    ]
                  ) + 2;
                this.gsheetService
                  .updateNotificationStatus(row)
                  .then((response: any) => {})
                  .catch((error) => {
                    console.error(error);
                    alert(
                      'Sorry, there was an error in updating notification status.'
                    );
                  });
              }
            }
            resolve(true);
          })
          .catch((error) => {
            const user = JSON.parse(localStorage.getItem('user'));
            alert(
              `Sorry, there was an error in sending notification: ${error.code}. UID - ${user.uid}`
            );
          });
      }
    });
    return promise;
  }

  get title() {
    return this.postForm.get('title');
  }

  get text() {
    return this.postForm.get('text');
  }

  get recipients() {
    return this.postForm.get('recipients');
  }

  getGSheetData() {
    // Locking button while processing
    this.automatedNotificationsActive = true;
    this.gsheetService
      .getAutomatedNotifications()
      .then((response: any) => {
        console.log('response', response);
        this.automatedNotifications = response;
        this.filterAutomatedNotifications();
      })
      .catch((error) => {
        console.error(error);
        alert('Sorry, unable to retrieve notifications. Please try again.');
        // Releasing lock
        this.automatedNotificationsActive = false;
      });
  }

  filterAutomatedNotifications() {
    var tmp = new Array();
    var recipients = new Array();

    this.automatedNotifications.forEach((element, index) => {
      if (element.includes('Sent', 3) || element.includes('sent', 3)) {
        // Notification is already sent, skipping
      } else {
        // Pushing index
        element.push(index.toString());
        tmp.push(element);
        recipients.push(element[2]);
      }
    });

    this.automatedNotifications = tmp;

    if (this.automatedNotifications.length != 0) {
      this.initAutomatedNotifications(recipients);
    } else {
      // All notifications are send
      alert('No new notification to send, already sent all of them.');
      // Releasing lock
      this.automatedNotificationsActive = false;
    }
  }

  async initAutomatedNotifications(recipients: Array<string>) {
    recipients.forEach((element, index) => {
      // Trimming last item (if necessary)
      if (element.slice(-1) == ';') {
        recipients[index] = element.slice(0, -1);
      }
    });
    let recipientsString = recipients.join(';');
    let recipientsArray = new Array();

    if (recipientsString) {
      // Replacing line brakes
      let cleanedRecipients = recipientsString.replace(/(\r\n|\n|\r)/gm, '');

      // String to array
      recipientsArray = cleanedRecipients.split(';');
    }

    let message =
      'Are you sure to send ' +
      this.automatedNotifications.length +
      ' notification(s) to ' +
      recipientsArray.length +
      ' person(s)?';
    if (confirm(message)) {
      // this.sendAutomatedNotifications();
      for (let index = 0; index < this.automatedNotifications.length; index++) {
        this.notificationAutoMode = true;
        const element = this.automatedNotifications[index];
        this.currentAutomatedNotification = element;
        await this.sendAutomatedNotifications();
      }
      // Release button lock on completion
      this.automatedNotificationsActive = false;
    } else {
      // Release button lock
      this.automatedNotificationsActive = false;
    }
  }

  async sendAutomatedNotifications() {
    var promise = new Promise(async (resolve, reject) => {
      await this.initNotification();
      resolve();
    });
    return promise;
  }

  public typeChanged(e: any) {
    let title: string = '';
    let text: string = '';
    if (e.value == '1') {
      title = this.posts[0].title.substring(0, 30);
      text = this.getText(this.posts[0].content);
    }

    this.postForm.patchValue({
      title: title,
      text: text,
    });
  }

  private getText(html: string) {
    var tmp = document.createElement('div');
    tmp.innerHTML = html;

    return tmp.textContent.substring(0, 500) || tmp.innerText.substring(0, 500);
  }

  public postChanged(e: any) {
    this.postForm.patchValue({
      title: this.posts[this.postForm.value.post].title.substring(0, 30),
      text: this.getText(this.posts[this.postForm.value.post].content),
    });
  }

  private postVisibilityCheck(device) {
    return new Promise(async (resolve, reject) => {
      let status = false;
      this.posts[this.postForm.value.post].visibility.forEach((element) => {
        if (!status && device.userType == element) {
          status = true;
        }
      });
      if (status) {
        resolve();
      } else {
        reject();
      }
    });
  }
}

<app-navigation></app-navigation>
<div class="container-fluid">
    <div class="row mb-3">
        <div class="col">
            <div class="md-form">
                <input type="text" class="form-control" [(ngModel)]="searchText" (keyup)="searchItems()"
                    id="search-input" mdbInput>
                <label for="search-input">Search</label>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
        <table mdbTable #tableEl="mdbTable" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
            <thead class="sticky-top">
                <tr>
                    <!-- <th *ngFor="let head of headElements; let i = index" [mdbTableSort]="elements"
                        [sortBy]="headElements[i]" scope="col">{{head | titlecase}}
                        <mdb-icon fas icon="sort"></mdb-icon>
                    </th> -->
                    <th [mdbTableSort]="elements" sortBy="id">#
                        <mdb-icon fas icon="sort"></mdb-icon>
                    </th>
                    <th [mdbTableSort]="elements" sortBy="forename">Student
                        <mdb-icon fas icon="sort"></mdb-icon>
                    </th>
                    <th [mdbTableSort]="elements" sortBy="yearGroup">Grade
                        <mdb-icon fas icon="sort"></mdb-icon>
                    </th>
                    <th>Status</th>
                    <th>Contact(s)</th>
                    <th>Date</th>
                    <th>Time</th>
                </tr>
            </thead>
            <tbody #row>
                <tr mdbTableCol (rowCreated)="onRowCreate($event)" (rowRemoved)="onRowRemove($event)"
                    *ngFor="let el of elements; let i = index">
                    <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex"
                        scope="row">{{ el.id }}
                    </th>
                    <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex"
                        scope="row">{{ el.forename }}&nbsp;{{ el.surname }}
                    </th>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                        {{ el.yearGroup }}
                    </td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                        <ng-container *ngFor="let reRegistration of el.reRegistration">
                            <ng-container *ngFor="let child of reRegistration.children">
                                <ng-container *ngIf="child.schoolEmailAddress == el.schoolEmailAddress">
                                    <span *ngIf="child.status == true">Re-registered</span>
                                    <span *ngIf="child.status == false">Withdrawn</span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <span *ngIf="el.reRegistration.length == 0">NA</span>
                    </td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                        <ng-container *ngFor="let parent of el.parentDetails; let i = index">
                            <span *ngIf="parent.forename && parent.surname">{{ parent?.forename }}&nbsp;{{ parent?.surname }}&nbsp;({{ parent.emailAddress }})
                                <span *ngIf="parent.deviceDetails">
                                    <span *ngIf="parent.deviceDetails"></span>
                                    <i *ngIf="parent.deviceDetails.daysSinceLastLogin <= 60" class="fas fa-mobile-alt" mdbTooltip="Last login was {{parent.deviceDetails.daysSinceLastLogin | number:'1.0-0'}} day(s) ago" placement="top">&nbsp;</i>
                                    <i *ngIf="parent.deviceDetails.device?.platform == 'iOS'" class="fab fa-apple"></i>
                                    <i *ngIf="parent.deviceDetails.device?.platform == 'Android'" class="fab fa-android"></i>
                                </span>
                                <span *ngIf="parent.deviceDetails">&nbsp;{{ parent.deviceDetails.app?.versionNumber }}</span>
                                <br>
                            </span>
                            <span *ngIf="!parent.forename && !parent.surname">{{ parent.emailAddress }}<br></span>
                        </ng-container>
                        <span *ngIf="el.parentDetails.length == 0">No Contacts</span>
                    </td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                        <span *ngIf="el.reRegistration.length == 0">NA</span>
                        <span *ngIf="el.reRegistration.length > 0">{{ el.reRegistration[0].dateCreated.toDate() | date: 'dd/MM/yyyy' }}</span>
                    </td>
                    <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                        <span *ngIf="el.reRegistration.length == 0">NA</span>
                        <span *ngIf="el.reRegistration.length > 0">{{ el.reRegistration[0].dateCreated.toDate() | date: 'HH:mm:ss' }}</span>
                    </td>
                </tr>
            </tbody>
            <tfoot class="grey lighten-5 w-100">
                <tr>
                    <td colspan="7">
                        <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="elements">
                        </mdb-table-pagination>
                    </td>
                </tr>
            </tfoot>
        </table>
        </div>
    </div>
</div>
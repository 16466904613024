import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let redirectUrlKey: string = 'redirectUrl';
    let redirectUrl: string = localStorage.getItem(redirectUrlKey);
    if (this.authService.isLoggedIn !== true) {
      // Removing leading slash
      let url = state.url.replace(/^\/+/, '');
      localStorage.setItem(redirectUrlKey, url);
      this.router.navigate(['sign-in']);
    } else if (redirectUrl) {
      localStorage.removeItem(redirectUrlKey);
      this.router.navigate([redirectUrl]);
    } else {
      return true;
    }
  }
}

import { Injectable } from '@angular/core';

import { credentials } from '../../credentials';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class IsamsService {

  isams = credentials.isamsConfig;
  isamsToken: any;
  userInfo: any;

  constructor(
    private httpClient: HttpClient,
    private firestore: AngularFirestore
  ) { }

  encodeQueryData(data: object) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
 }

  getToken() {
    let params = {
      'client_id': this.isams.clientId,
      'client_secret': this.isams.clientSecret,
      'grant_type': this.isams.grantType,
      'scope': this.isams.scope
    };
    // Converting object to url parameters
    let urlParams = Object.entries(params).map(e => e.join('=')).join('&');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    // Calling iSAMS endpoint for Bearer token
    return this.httpClient.post(this.isams.host + this.isams.endPoint, params, httpOptions).toPromise();
    return this.httpClient.post('/isams-api' + this.isams.endPoint, this.encodeQueryData(params), httpOptions)
      .pipe(
        catchError(error => error)
      );
    //   .then(data => {
    //     console.log('Data from getToken');
    //     return data;
    //   })
    //   .catch(error => {
    //     console.error('Error retrieving iSAMS token', error);
    //     return error;
    //   })
    // return this.isamsToken;
  }

  getAllStaff(tokenType: string, token: string) {
    const header = { 'Authorisation': tokenType + ' ' + token };

    return this.httpClient.get('/isams-api' + '/humanresources/employees', {
      responseType: 'json',
      headers: header
    })
    .pipe(
      catchError(error => error)
    );
  }

  getYearGroups(tokenType: string, token: string) {
    try {
      const header = { 'Authorisation': tokenType + ' ' + token };

      return this.httpClient.get('/isams-api' + '/school/yeargroups', {
        responseType: 'json',
        headers: header
      })
      .pipe(
        catchError(error => error)
      );
    } catch (error) {
      console.error('Error in getting iSAMS year groups', error);
    }
  }

  getIsamsSync() {
    var beginningDateObject = new Date();
    beginningDateObject.setHours(0, 0, 0, 0);
    // beginningDateObject.setHours(17, 0, 0, 0);
    return this.firestore.collection('isamsSync', ref => ref
    .orderBy('dateCreated', 'desc')
    // Only current/active users
    .where('dateCreated', '>', beginningDateObject)
    ).snapshotChanges();
  }

  getIsamsSyncStudents() {
    var beginningDateObject = new Date();
    beginningDateObject.setHours(0, 0, 0, 0);
    return this.firestore.collection('isamsSync', ref => ref
    // Only current students
    .where('dateCreated', '>', beginningDateObject)
    .where('type', '==', 'student')
    ).snapshotChanges();
  }

  getIsamsSyncParents() {
    var beginningDateObject = new Date();
    beginningDateObject.setHours(0, 0, 0, 0);
    return this.firestore.collection('isamsSync', ref => ref
    // Only current parents
    .where('dateCreated', '>', beginningDateObject)
    .where('type', '==', 'parent')
    ).snapshotChanges();
  }
}

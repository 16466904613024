import { Component, OnInit, ElementRef, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';

import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { IsamsService } from '../../services/isams/isams.service';
import { DevicesService } from '../../services/devices/devices.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;

  elements: any = [];

  searchText: string = '';
  searchGradeText: string = '';
  previous: string;

  maxVisibleItems: number = 700;

  private usersObservable;
  private devicesObservable;

  private users: Array<any>;
  private devices: Array<any>;

  private allUsers: Array<any> = [];
  public allUsersCount: number = 0;
  private appUsers: Array<any> = [];
  public appUsersCount: number = 0;
  private nonAppUsers: Array<any> = [];
  private parents: Array<any> = [];
  public parentsCount: number = 0;
  private parentAppUsers: Array<any> = [];
  public parentAppUsersCount: number = 0;
  private parentNonAppUsers: Array<any> = [];
  private students: Array<any> = [];
  public studentsCount: number = 0;
  private studentAppUsers: Array<any> = [];
  public studentAppUsersCount: number = 0;
  private studentNonAppUsers: Array<any> = [];
  private staffs: Array<any> = [];
  public staffsCount: number = 0;
  private staffAppUsers: Array<any> = [];
  public staffAppUsersCount: number = 0;
  private staffNonAppUsers: Array<any> = [];
  private consultants: Array<any> = [];
  public consultantsCount: number = 0;
  private consultantsAppUsers: Array<any> = [];
  public consultantsAppUsersCount: number = 0;
  private consultantNonAppUsers: Array<any> = [];
  private board: Array<any> = [];
  public boardCount: number = 0;
  private boardAppUsers: Array<any> = [];
  public boardAppUsersCount: number = 0;
  private boardNonAppUsers: Array<any> = [];
  private other: Array<any> = [];
  public otherCount: number = 0;
  private otherAppUsers: Array<any> = [];
  public otherAppUsersCount: number = 0;
  private otherNonAppUsers: Array<any> = [];

  public filterKey: number = 0;
  public filterText: string = 'All users';
  private filter2Key: number = 0;
  public filter2Text: string = 'All types';

  public testUsers: Array<string> = ['halcyonappparent@gmail.com', 'appparenttest@halcyonschool.com', 'appstafftest@halcyonschool.com', 'appstaff@halcyonschool.com'];
  public filterOptions: Array<any>;
  public selectedOptions: Array<any>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private isamsService: IsamsService,
    private devicesService: DevicesService
  ) { }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getUsers();
    this.filterOptions = [
      { value: 0, label: 'users', selected: true },
      { value: 1, label: 'parents' },
      { value: 2, label: 'students' },
      { value: 3, label: 'staff' },
      { value: 4, label: 'consultants' },
      { value: 5, label: 'board' },
      { value: 6, label: 'other' }
    ];
    this.selectedOptions = [0, 1, 2, 3, 4, 5, 6];
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.cdRef.detectChanges();
  }

  public searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      // this.elements = this.mdbTable.searchLocalDataByFields(this.searchText, ['forename', 'surname', 'yearGroup', 'reRegistration.children.status']);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  public searchGrades() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchGradeText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchGradeText) {
      // this.elements = this.mdbTable.searchLocalDataBy(this.searchGradeText);
      this.elements = this.mdbTable.searchLocalDataByFields(this.searchGradeText, ['yearGroupString']);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  private getUsers() {
    this.usersObservable = this.isamsService.getIsamsSync().subscribe((response) => {
      this.users = response.map(e => {
        return {
          id: e.payload.doc.id,
          // "as {}" is added for fixing TS generic type spread limitation
          // https://github.com/Microsoft/TypeScript/issues/10727 
          ...e.payload.doc.data() as {}
        }
      });
      this.getDevices();
    });
  }

  private getDevices() {
    this.devicesObservable = this.devicesService.getDevices().subscribe((response) => {
      this.devices = response.map(e => {
        const user = e.payload.doc.get('user');
        return {
          // id: e.payload.doc.id,
          id: (user) ? user.email : null,
          ...e.payload.doc.data() as {}
        }
      });
      this.clubData();
    });
  }

  private clubData() {
    let i = 1;
    this.users.forEach((user, ix) => {
      user.id = i;
      i++;
      let excluded = false;

      // Adding device details
      if (user.type == 'parent') {
        if (this.testUsers.indexOf(user.emailAddress) != -1) {
          // Excluding all test users from analytics
          excluded = true;
        }
        user.deviceDetails = this.devices.find(d => d.user.email == user.emailAddress);
      } else {
        if (this.testUsers.indexOf(user.schoolEmailAddress) != -1) {
          // Excluding all test users from analytics
          // excluded = true;
        }
        user.deviceDetails = this.devices.find(d => d.user.email == user.schoolEmailAddress);
      }

      if (user.deviceDetails) {
        user.deviceDetails.daysSinceLastLogin = this.numDaysBetween(user.deviceDetails.dateCreated.toDate());
        // For sorting
        user.daysSinceLastLogin = Math.round(user.deviceDetails.daysSinceLastLogin);
        // user.daysSinceLastLogin = (user.deviceDetails.daysSinceLastLogin).toFixed(2);
        
      } else {
        // For sorting
        user.daysSinceLastLogin = -1;
      }

      if (user.type == 'student') {
        if (user.yearGroup) {
          // For search
          user.yearGroupString = user.yearGroup.toString();
        }
      }

      if (user.type == 'staff') {
        const emailDomain = user.schoolEmailAddress.split('@');
        if (emailDomain[1] == 'halcyonschool.com') {
          // Excluding all non halcyon staff from analytics
          // excluded = true;
      } else if (emailDomain[1] == 'consultants.halcyonschool.com') {
          // Consultants
          user.type = 'consultant';
        } else if (emailDomain[1] == 'board.halcyonschool.com') {
          // Board
          user.type = 'board';
        } else {
          // Other
          user.type = 'other';
        }
      }

      // user.excluded = excluded;

      // if (!excluded) {
        this.elements.push(user);
      // }
    });

    // Preserving
    this.allUsers = this.elements;

    this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();

    this.usersObservable.unsubscribe();
    this.devicesObservable.unsubscribe();

    this.generateAnalytics();
  }

  private numDaysBetween(d: Date) {
    const d1 = new Date(d);
    // Today
    const d2 = new Date();
    var diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
  };

  public copyToClipBoard(id: number) {
    const user = this.users[(id - 1)];
    const userEmail = (user.type == 'parent') ? user.emailAddress : user.schoolEmailAddress;
    // let text = user.forename + '	' + user.surname + '	' + userEmail;
    let text = user.forename + ' ' + user.surname + ', ' + userEmail;
    console.log(text);
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private generateAnalytics() {
    this.users.forEach(element => {
      // if (!element.excluded) {
        if (element.type == 'parent') {
          this.parents.push(element);
        } else if (element.type == 'student') {
          this.students.push(element);
        } else if (element.type == 'staff') {
            this.staffs.push(element);
        } else if (element.type == 'consultant') {
          this.consultants.push(element);
        } else if (element.type == 'board') {
          this.board.push(element);
        } else if (element.type == 'other') {
          this.other.push(element);
        }

        if (element.deviceDetails) {
          this.appUsers.push(element);

          if (element.type == 'parent') {
            this.parentAppUsers.push(element);
          } else if (element.type == 'student') {
            this.studentAppUsers.push(element);
          } else if (element.type == 'staff') {
            this.staffAppUsers.push(element);
          } else if (element.type == 'consultant') {
            this.consultantsAppUsers.push(element);
          } else if (element.type == 'board') {
            this.boardAppUsers.push(element);
          } else if (element.type == 'other') {
            this.otherAppUsers.push(element);
          }
        } else {
          this.nonAppUsers.push(element);

          if (element.type == 'parent') {
            this.parentNonAppUsers.push(element);
          } else if (element.type == 'student') {
            this.studentNonAppUsers.push(element);
          } else if (element.type == 'staff') {
            this.staffNonAppUsers.push(element);
          } else if (element.type == 'consultant') {
            this.consultantNonAppUsers.push(element);
          } else if (element.type == 'board') {
            this.boardNonAppUsers.push(element);
          } else if (element.type == 'other') {
            this.otherNonAppUsers.push(element);
          }
        }
      // }
    });

    this.parentsCount = this.parents.length;
    this.parentAppUsersCount = this.parentAppUsers.length;
    this.studentsCount = this.students.length;
    this.studentAppUsersCount = this.studentAppUsers.length;
    this.staffsCount = this.staffs.length;
    this.staffAppUsersCount = this.staffAppUsers.length;
    this.consultantsCount = this.consultants.length;
    this.consultantsAppUsersCount = this.consultantsAppUsers.length;
    this.boardCount = this.board.length;
    this.boardAppUsersCount = this.boardAppUsers.length;
    this.otherCount = this.other.length;
    this.otherAppUsersCount = this.otherAppUsers.length;

    this.allUsersCount = this.parentsCount + this.studentsCount + this.staffsCount + this.consultantsCount + this.boardCount + this.otherCount;
    this.appUsersCount = this.parentAppUsersCount + this.studentAppUsersCount + this.staffAppUsersCount + this.consultantsAppUsersCount + this.boardAppUsersCount + this.boardAppUsersCount;
    // let test = this.users.reduce((acc, curr) => curr.type == 'parent' ? ++acc : acc, 0);
  }

  public filterData(key?: number, key2?: number) {
    if (key != null) {
      this.filterKey = key;
    }
    if (key2 != null) {
      this.filter2Key = key2;
    }

    if (this.filter2Key == 0) {
      // All users
      this.filter2Text = 'All type';

      if (this.filterKey == 0) {
        // All users
        this.mdbTable.setDataSource(this.elements);
        this.filterText = 'All users';
      } else if (this.filterKey == 1) {
        // All parents
        this.mdbTable.setDataSource(this.parents);
        this.filterText = 'Parents';
      } else if (this.filterKey == 2) {
        // All students
        this.mdbTable.setDataSource(this.students);
        this.filterText = 'Students';
      } else if (this.filterKey == 3) {
        // All staff
        this.mdbTable.setDataSource(this.staffs);
        this.filterText = 'Staff';
      }
    } else if (this.filter2Key == 1) {
      // App users
      this.filter2Text = 'App users';

      if (this.filterKey == 0) {
        // All users
        this.mdbTable.setDataSource(this.appUsers);
        this.filterText = 'All users';
      } else if (this.filterKey == 1) {
        // All parents
        this.mdbTable.setDataSource(this.parentAppUsers);
        this.filterText = 'Parents';
      } else if (this.filterKey == 2) {
        // All students
        this.mdbTable.setDataSource(this.studentAppUsers);
        this.filterText = 'Students';
      } else if (this.filterKey == 3) {
        // All staff
        this.mdbTable.setDataSource(this.staffAppUsers);
        this.filterText = 'Staff';
      }
    } else if (this.filter2Key == 2) {
      // Non app users
      this.filter2Text = 'Non app users';

      if (this.filterKey == 0) {
        // All users
        this.mdbTable.setDataSource(this.nonAppUsers);
        this.filterText = 'All users';
      } else if (this.filterKey == 1) {
        // All parents
        this.mdbTable.setDataSource(this.parentNonAppUsers);
        this.filterText = 'Parents';
      } else if (this.filterKey == 2) {
        // All students
        this.mdbTable.setDataSource(this.studentNonAppUsers);
        this.filterText = 'Students';
      } else if (this.filterKey == 3) {
        // All staff
        this.mdbTable.setDataSource(this.staffNonAppUsers);
        this.filterText = 'Staff';
      }
    }

    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
  }

  public filterSelected(event: Event) {
    console.log('filterSelected', event);
  }

  public filterDeselected(event: Event) {
    console.log('filterDeselected', event);
  }

  public getSelectedValues(event: any) {
    console.log('Selected value', event);
    if (event.length == 0) {
      console.log('Empty');
      this.mdbTable.setDataSource([]);
      this.elements = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
      return;
    }

    let tmp = new Array();
    event.forEach(element => {
      console.log(element);
      if (element == 0) {
        // All users
        tmp = this.allUsers;
      } else {
        if (element == 1) {
          tmp = tmp.concat(this.parents);
        } else if (element == 2) {
          tmp = tmp.concat(this.students);
        } else if (element == 3) {
          tmp = tmp.concat(this.staffs);
        } else if (element == 4) {
          tmp = tmp.concat(this.consultants);
        } else if (element == 5) {
          tmp = tmp.concat(this.board);
        } else if (element == 6) {
          tmp = tmp.concat(this.other);
        }
      }
    });
    console.log('tmp', tmp);
    this.mdbTable.setDataSource(tmp);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
  }

  public generateCsv() {
    const options = {
      headers: ['Name', 'Email', 'Type'],
      nullToEmptyString: true
    }

    let tmp = new Array();
    this.elements.forEach(element => {
      tmp.push({
        name: element.forename + ' ' + element.surname,
        email: (element.schoolEmailAddress) ? element.schoolEmailAddress : element.emailAddress,
        type: element.type
      });
    });
    new AngularCsv(tmp, 'halcyon-analytics', options);
  }
}

<app-navigation></app-navigation>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="card card-body">
        <div class="card-title">
          <h1 class="h2">Notifications Log</h1>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped table-hover">
              <thead>
                <tr class="text-bold">
                  <th scope="col">Date Time</th>
                  <th scope="col">Title</th>
                  <th scope="col">Text</th>
                  <th scope="col">Type</th>
                  <th scope="col">Initiator</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let notification of notifications | async">
                  <td>{{ notification.dateUpdated?.toDate() | date: 'medium' }}</td>
                  <td>{{ (notification.title?.length > 50) ? (notification.title | slice:0:50) + '...' : (notification.title) }}</td>
                  <td>{{ (notification.text?.length > 50) ? (notification.text | slice:0:50) + '...' : (notification.text) }}</td>
                  <td *ngIf="!notification.type">
                    <i mdbTooltip="Manual" placement="top" class="fas fa-user"></i>
                  </td>
                  <td *ngIf="notification.type && notification.type == 'manual' || notification.initiationType == 'manual'">
                    <i mdbTooltip="Manual" placement="top" class="fas fa-user"></i>
                  </td>
                  <td *ngIf="notification.type && notification.type == 'automated' || notification.initiationType == 'automated'">
                    <i mdbTooltip="From Sheet" placement="top" class="fas fa-table"></i>
                  </td>
                  <td>{{ notification.initiator.displayName }}</td>
                  <ng-template #popTemplate>
                    <div *ngIf="!notification.response && !notification.nonAppUsers">No Response</div>
                    <div *ngIf="notification.response">Success Count: {{notification.response.successCount}}</div>
                    <div *ngIf="notification.response">Failure Count: {{notification.response.failureCount}}</div>
                    <div *ngIf="notification.nonAppUsers">Non App Users: {{ (notification.nonAppUsers).length }}</div>
                  </ng-template>
                  <td *ngIf="notification.status == 'not-sent'" class="text-capitalize font-weight-bold text-danger" [mdbTooltip]="popTemplate">0 / {{ (notification.nonAppUsers).length }} (0%)</td>
                  <td *ngIf="notification.response" class="text-capitalize font-weight-bold" [mdbTooltip]="popTemplate" [ngClass]="{'text-danger' : notification.response.successCount == 0, 'text-success' : notification.response.successCount != 0}">{{ notification.response.successCount }} / {{ (notification.nonAppUsers).length + (notification.tokens).length }} ({{ (notification.response.successCount * (100 / ((notification.nonAppUsers).length + (notification.tokens).length))) | number: '1.0-0' }}%)</td>
                  <td *ngIf="notification.status == 'enqueued' && !notification.response && notification.tokens" class="text-capitalize font-weight-bold" [mdbTooltip]="popTemplate">
                    <span *ngIf="notification.nonAppUsers.length" class="text-success">{{ (notification.tokens).length }} / {{ (notification.nonAppUsers).length + (notification.tokens).length }} ({{ ((notification.tokens).length * (100 / ((notification.nonAppUsers).length + (notification.tokens).length))) | number: '1.0-0' }}%)</span>
                    <span *ngIf="!notification.nonAppUsers.length && notification.tokens.length" class="text-success">{{ (notification.tokens).length }} / {{ (notification.tokens).length }} ({{ ((notification.tokens).length * (100 / ((notification.tokens).length))) | number: '1.0-0' }}%)</span>
                  </td>
                  <td *ngIf="notification.status == 'enqueued' && !notification.response && !notification.tokens" class="text-capitalize font-weight-bold" [mdbTooltip]="popTemplate">
                    <span *ngIf="notification.nonAppUsers.length" class="text-danger">0 / {{ (notification.nonAppUsers).length }}</span>
                    <span *ngIf="!notification.nonAppUsers.length" class="text-warning">
                        <i class="fas fa-exclamation-circle pl-2"></i>
                    </span>
                  </td>
                  <td *ngIf="!notification.tokens.length && !notification.response && !notification.nonAppUsers.length" class="text-warning">
                    <i class="far fa-meh-rolling-eyes pl-2"></i>
                  </td>
                  <td> <button (click)="duplicate(notification.id)" mdbBtn type="button" size="sm" [ngClass]="{'disabled': currentUser.role === 0}" class="waves-light n27-button-primary" mdbWavesEffect>Duplicate</button></td>
                  <!-- <td> <button (click)="delete(notification.id)" mdbBtn type="button" color="danger" size="sm" class="waves-light" mdbWavesEffect>Delete</button></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private firestore: AngularFirestore) { }

  getDevices() {
    return this.firestore.collection('devices', ref => ref
    .orderBy('dateCreated', 'desc')
    .orderBy('user.email', 'desc')
    // .where('user.email', '>', '')
    // .where('user.email', '==', 'janicethrush@gmail.com')
    ).snapshotChanges();
  }
}

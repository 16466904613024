<app-navigation></app-navigation>
<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="alert alert-light" role="alert">
                Data excludes&nbsp;{{ testUsers.length }}&nbsp;test users.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <!-- <table class="table text-center">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            <span class="font-weight-bold">Users</span>
                            <br>
                            <span>{{ appUsersCount }}/{{ allUsers }}&nbsp;({{ (appUsersCount * (100 / allUsers)) | number: '1.1-1' }}%)</span>
                        </th>
                        <th scope="col">
                            <span class="font-weight-bold">Parents</span>
                            <br>
                            <span>{{ parentAppUsersCount }}/{{ parentsCount }}&nbsp;({{ (parentAppUsersCount * (100 / parentsCount)) | number: '1.1-1' }}%)</span>
                        </th>
                        <th scope="col">
                            <span class="font-weight-bold">Students</span>
                            <br>
                            <span>{{ studentAppUsersCount }}/{{ studentsCount }}&nbsp;({{ (studentAppUsersCount * (100 / studentsCount)) | number: '1.1-1' }}%)</span>
                        </th>
                        <th scope="col">
                            <span class="font-weight-bold">Staff</span>
                            <br>
                            <span>{{ staffAppUsersCount }}/{{ staffsCount }}&nbsp;({{ (staffAppUsersCount * (100 / staffsCount)) | number: '1.1-1' }}%)</span>
                        </th>
                    </tr>
                </thead>
            </table> -->
            <section class="mx-3 mb-4 pb-3">
                <div class="card-deck text-center">
                    <mdb-card class="mr-3">
                        <mdb-card-body>
                            <mdb-card-title>
                                <h5>Users</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                {{ appUsersCount }}/{{ allUsersCount }}&nbsp;({{ (appUsersCount * (100 / allUsersCount)) | number: '1.1-1' }}%)
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card class="mr-3">
                        <mdb-card-body>
                            <mdb-card-title>
                                <h5>Parents</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                {{ parentAppUsersCount }}/{{ parentsCount }}&nbsp;({{ (parentAppUsersCount * (100 / parentsCount)) | number: '1.1-1' }}%)
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card class="mr-3">
                        <mdb-card-body>
                            <mdb-card-title>
                                <h5>Students</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                {{ studentAppUsersCount }}/{{ studentsCount }}&nbsp;({{ (studentAppUsersCount * (100 / studentsCount)) | number: '1.1-1' }}%)
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card class="mr-3">
                        <mdb-card-body>
                            <mdb-card-title>
                                <h5>Staff</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                {{ staffAppUsersCount }}/{{ staffsCount }}&nbsp;({{ (staffAppUsersCount * (100 / staffsCount)) | number: '1.1-1' }}%)
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card class="mr-3">
                        <mdb-card-body>
                            <mdb-card-title>
                                <h5>Consultants</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                {{ consultantsAppUsersCount }}/{{ consultantsCount }}&nbsp;({{ (consultantsAppUsersCount * (100 / consultantsCount)) | number: '1.1-1' }}%)
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card class="mr-3">
                        <mdb-card-body>
                            <mdb-card-title>
                                <h5>Board</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                {{ boardAppUsersCount }}/{{ boardCount }}&nbsp;({{ (boardAppUsersCount * (100 / boardCount)) | number: '1.1-1' }}%)
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                    <mdb-card>
                        <mdb-card-body>
                            <mdb-card-title>
                                <h5>Other</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                {{ otherAppUsersCount }}/{{ otherCount }}&nbsp;({{ (otherAppUsersCount * (100 / otherCount)) | number: '1.1-1' }}%)
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                </div>
            </section>
        </div>
    </div>
    <div class="row mx-1 mb-4 actions">
        <div class="col-3">
            <!-- <div class="dropdown" mdbDropdown>
                <button mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light" type="button"
                    mdbWavesEffect>
                    {{ filterText }}
                </button>

                <div class="dropdown-menu dropdown-primary">
                    <a class="dropdown-item" (click)="filterData(0)">All Users</a>
                    <a class="dropdown-item" (click)="filterData(1)">Parents</a>
                    <a class="dropdown-item" (click)="filterData(2)">Students</a>
                    <a class="dropdown-item" (click)="filterData(3)">Staff</a>
                </div>
            </div> -->
            <mdb-select class="colorful-select dropdown-primary" (ngModelChange)="getSelectedValues($event)" [(ngModel)]="selectedOptions" [options]="filterOptions" [multiple]="true" [highlightFirst]="false" [enableSelectAll]="false" [visibleOptions]="7" [allowClear]="true" optionHeight="50" [tabindex]="-1" placeholder="Choose your option" (selected)="filterSelected($event)" (deselected)="filterDeselected($event)"></mdb-select>
            <label>Group Filter</label>
        </div>
        <!-- <div class="col-2">
            <div class="dropdown" mdbDropdown>
                <button mdbDropdownToggle mdbBtn color="primary" class="dropdown-toggle waves-light" type="button"
                    mdbWavesEffect>
                    {{ filter2Text }}
                </button>
                <div class="dropdown-menu dropdown-primary">
                    <a class="dropdown-item" (click)="filterData(null, 0)">All types</a>
                    <a class="dropdown-item" (click)="filterData(null, 1)">App users</a>
                    <a class="dropdown-item" (click)="filterData(null, 2)">Non app users</a>
                </div>
            </div>
        </div> -->
        <div class="col">
            <div class="md-form">
                <input type="text" class="form-control" [(ngModel)]="searchText" (keyup)="searchItems()"
                    id="search-input" mdbInput>
                <label for="search-input">Search</label>
            </div>
        </div>
        <!-- <div [hidden]="filterKey != 2" class="col">
            <div class="md-form">
                <input type="text" class="form-control" [(ngModel)]="searchGradeText" (keyup)="searchGrades()"
                    id="search-input-2" mdbInput>
                <label for="search-input-2">Grade</label>
            </div>
        </div> -->
    </div>

    <div class="row mb-3">
        <div class="col">
            <table mdbTable #tableEl="mdbTable" stickyHeader="true" hover="true" striped="true" class="z-depth-1 op-9">
                <thead class="sticky-top">
                    <tr>
                        <th [mdbTableSort]="elements" sortBy="id">#
                            <mdb-icon fas icon="sort"></mdb-icon>
                        </th>
                        <th [mdbTableSort]="elements" sortBy="forename">Name
                            <mdb-icon fas icon="sort"></mdb-icon>
                        </th>
                        <th>Email</th>
                        <th [mdbTableSort]="elements" sortBy="type">Group
                            <mdb-icon fas icon="sort"></mdb-icon>
                        </th>
                        <th>Grade/Role(s)</th>
                        <th [mdbTableSort]="elements" sortBy="daysSinceLastLogin">Last Login
                            <mdb-icon fas icon="sort"></mdb-icon>
                        </th>
                        <th>OS</th>
                        <th>App Version</th>
                    </tr>
                </thead>
                <tbody #row>
                    <tr mdbTableCol (rowCreated)="onRowCreate($event)" (rowRemoved)="onRowRemove($event)"
                        *ngFor="let el of elements; let i = index" mdbTooltip="Click to copy" placement="top"
                        (click)="copyToClipBoard(el.id)">
                        <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex"
                            scope="row">{{ el.id }}
                        </th>
                        <th *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex"
                            scope="row">
                            <span class="text-capitalize">{{ el.forename }}&nbsp;{{ el.surname }}</span>
                            <ng-container *ngIf="el.type == 'parent'">
                                <ng-container *ngFor="let child of el.children">
                                    <br>
                                    <i class="ml-3 fas fa-level-up-alt fa-rotate-90 mr-2"></i>
                                    <span>{{ child.schoolEmailAddress }}</span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="el.type == 'student'">
                                <ng-container *ngFor="let parent of el.parents">
                                    <br>
                                    <i class="ml-3 fas fa-level-up-alt fa-rotate-90 mr-2"></i>
                                    <span>{{ parent.emailAddress }}</span>
                                </ng-container>
                            </ng-container>
                        </th>
                        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                            <ng-container *ngIf="el.type == 'parent'">
                                <span>{{ el.emailAddress }}</span>
                            </ng-container>
                            <ng-container *ngIf="el.type != 'parent'">
                                <span>{{ el.schoolEmailAddress }}</span>
                            </ng-container>
                        </td>
                        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                            <span class="text-capitalize">{{ el.type }}</span>
                        </td>
                        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                            <ng-container *ngIf="el.type == 'student'">
                                <span class="text-capitalize">Grade&nbsp;{{ el.yearGroup }}</span>
                            </ng-container>
                            <ng-container *ngIf="el.type == 'staff'">
                                <ng-container *ngFor="let role of el.roles; let i = index;">
                                    <span *ngIf="i != 0">,&nbsp;</span>
                                    <span class="text-capitalize">{{ role.name }}</span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="el.type == 'parent'">NA</ng-container>
                        </td>
                        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i <  mdbTablePagination.lastItemIndex">
                            <span *ngIf="el.deviceDetails">
                                <span>{{ el.daysSinceLastLogin }}&nbsp;day(s)&nbsp;ago&nbsp;</span>
                                <i *ngIf="el.deviceDetails.daysSinceLastLogin < 1"
                                class="fas fa-mobile-alt text-success"
                                mdbTooltip="Last login was today"
                                placement="top">&nbsp;</i>
                                <i *ngIf="el.deviceDetails.daysSinceLastLogin >= 1 && el.deviceDetails.daysSinceLastLogin <= 14"
                                    class="fas fa-mobile-alt text-success"
                                    mdbTooltip="Last login was {{ el.deviceDetails.daysSinceLastLogin | number:'1.0-0' }} day(s) ago"
                                    placement="top">&nbsp;</i>
                                <i *ngIf="el.deviceDetails.daysSinceLastLogin > 14 && el.deviceDetails.daysSinceLastLogin <= 60"
                                    class="fas fa-mobile-alt"
                                    mdbTooltip="Last login was {{ el.deviceDetails.daysSinceLastLogin | number:'1.0-0' }} day(s) ago"
                                    placement="top">&nbsp;</i>
                                <i *ngIf="el.deviceDetails.daysSinceLastLogin > 60"
                                    class="fas fa-mobile-alt text-warning"
                                    mdbTooltip="Last login was {{ el.deviceDetails.daysSinceLastLogin | number:'1.0-0' }} day(s) ago"
                                    placement="top">&nbsp;</i>
                            </span>
                            <span *ngIf="!el.deviceDetails">NA</span>
                        </td>
                        <td>
                            <span *ngIf="el.deviceDetails?.device">
                                <i *ngIf="el.deviceDetails.device?.platform == 'iOS'" class="fab fa-apple"></i>
                                <i *ngIf="el.deviceDetails.device?.platform == 'Android'" class="fab fa-android"></i>
                                <span *ngIf="el.deviceDetails.device.version">&nbsp;{{ el.deviceDetails.device?.version }}</span>
                            </span>
                            <span *ngIf="!el.deviceDetails?.device">NA</span>
                        </td>
                        <td>
                            <span *ngIf="el.deviceDetails?.app">
                                <span *ngIf="el.deviceDetails">&nbsp;{{ el.deviceDetails.app?.versionNumber }}</span>    
                            </span>
                            <span *ngIf="!el.deviceDetails?.app">NA</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot class="grey lighten-5 w-100">
                    <tr>
                        <td colspan="8">
                            <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="elements">
                            </mdb-table-pagination>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <button [disabled]="elements.length == 0" mdbBtn type="button" color="light" mdbWavesEffect (click)="generateCsv()" mdbTooltip="Based on filters and search" placement="top">
                CSV Export
                <mdb-icon fas icon="file-export" class="ml-1"></mdb-icon>
            </button>
        </div>
    </div>
</div>
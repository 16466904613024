import { BrowserModule } from '@angular/platform-browser';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { credentials } from './credentials';
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthService } from "./services/auth/auth.service";
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NotificationComponent } from './components/notification/notification.component';

// Import below modules for NGX Toastr
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// Reactive Form Module
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddPostComponent } from './components/add-post/add-post.component';
import { PostListComponent } from './components/post-list/post-list.component';

import { DatePipe } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import 'froala-editor/js/plugins.pkgd.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgProgressModule } from '@ngx-progressbar/core';
import { ScanListComponent } from './components/scan-list/scan-list.component';
import { WhoIsInComponent } from './components/who-is-in/who-is-in.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { FindInIsamsPipe } from './pipes/find-in-isams.pipe';
import { ReRegistrationListComponent } from './components/re-registration-list/re-registration-list.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { IsamsYearGroupsComponent } from './modals/isams-year-groups/isams-year-groups.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    NavigationComponent,
    NotificationComponent,
    AddPostComponent,
    PostListComponent,
    PageNotFoundComponent,
    ScanListComponent,
    WhoIsInComponent,
    NotificationListComponent,
    FindInIsamsPipe,
    ReRegistrationListComponent,
    AnalyticsComponent,
    IsamsYearGroupsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(credentials.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    FormsModule,
    ReactiveFormsModule, // Reactive forms module
    MDBBootstrapModulesPro.forRoot(),
    // Can remove if going with Froala
    QuillModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgProgressModule
  ],
  providers: [
    AngularFirestore,
    AuthService,
    DatePipe,
    MDBSpinningPreloader
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

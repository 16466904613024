import {
  Component,
  OnInit,
  EventEmitter,
  NgZone,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';

import { PostService } from '../../services/post/post.service'; // Post services API
import { Post } from '../../models/post/post.model';
import { UploadService } from '../../services/upload/upload.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms'; // Reactive form services
import { ToastrService } from 'ngx-toastr'; // Alert message using NGX toastr
import { String } from 'aws-sdk/clients/elasticache';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import FroalaEditor from 'froala-editor';
import {
  NgProgressComponent,
  NgProgressRef,
  NgProgress,
} from '@ngx-progressbar/core';
import { auditTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss'],
})
export class AddPostComponent implements OnInit {
  private posts;

  self;
  public postForm: FormGroup; // Define FormGroup to post's form

  selectedFiles: FileList;
  panoramicImageOptions: Array<any>;
  optionsFeatured: Array<any>;
  optionsCategory: Array<any>;
  optionsTag: Array<any>;
  optionsVisibility: Array<any>;
  url: String;
  postId: String;
  featuredDisabled: Boolean = false;
  files: UploadFile[];
  dragOver: boolean;
  uploadInput: EventEmitter<UploadInput>;
  froalaInitControls;
  public froalaOptions: Record<string, any>;
  progressRef: NgProgressRef;
  @ViewChild('fr-image-by-url-layer-text-1') froalaCustomUrlInput: any;
  @ViewChild('froalaCustomInsert') froalaCustomInsert: ElementRef;
  renderer;
  private thumbnailImage;
  private existingPostData: any;
  private updatedVisibility = null;

  constructor(
    public postService: PostService, // CRUD API services
    public fb: FormBuilder, // Form Builder service for Reactive forms
    public toaster: ToastrService, // Toastr service for alert message
    private uploadService: UploadService,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private progress: NgProgress,
    private _elementRef: ElementRef,
    renderer: Renderer2
  ) {
    const self = this;
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.getPosts();
    this.froalaOptions = {
      key: environment.froala,
      iconsTemplate: 'font_awesome_5',
      attribution: false,
      charCounterCount: false,
      linkList: [
        {
          text: 'North27',
          href: 'https://north27.co.uk',
          target: '_self',
          rel: 'nofollow',
        },
        {
          text: 'Halcyon School',
          href: 'https://halcyonschool.com',
          target: '_self',
          rel: 'nofollow',
        },
      ],
      linkAlwaysBlank: true,
      quickInsertTags: [],
      placeholderText: 'Please Add Content Here!',
      pastePlain: true,
      toolbarButtons: [
        'bold',
        'italic',
        'underline',
        'paragraphFormat',
        '|',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'alignJustify',
        '|',
        'insertImage',
        'insertVideo',
        'insertLink',
        'myButton',
        'insertTable',
        '|',
        'undo',
        'redo',
      ],
      paragraphFormat: {
        N: 'Normal',
        H1: 'Heading 1',
        H2: 'Heading 2',
      },
      imageDefaultAlign: 'left',
      imageDefaultDisplay: 'inline-block',
      // Set the image upload parameter.
      imageUploadParam: 'image_param',
      // Set the image upload URL.
      imageUploadURL: 'assets/upload_image',
      // Additional upload params.
      imageUploadParams: { id: 'my_editor' },
      // Set request type.
      imageUploadMethod: 'POST',
      // Set max image size to 10MB.
      imageMaxSize: 10 * 1024 * 1024,
      // Allow to upload PNG and JPG.
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      videoAllowedProviders: ['youtube'],
      videoAllowedTypes: ['mp4'],
      // Set max video size to 100MB.
      videoMaxSize: 100 * 1024 * 1024,
      videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoUpload'],
      events: {
        'image.beforeUpload': function (images) {
          self.contentFileUpload(images[0]).then((result) => {
            this.image.insert(result);
          });
          return false;
        },
        'video.beforeUpload': function (videos) {
          self.contentFileUpload(videos[0]).then((result) => {
            // Custom video upload and insertion because Froala default does not work properly
            this.html.insert(
              '<span class="fr-video fr-fvc fr-dvb fr-draggable"><video controls><source src="' +
                result +
                '" type ="video/mp4"></src></video></span>'
            );
          });
          return false;
        },
        'link.beforeInsert': function (cmd, param1, param2) {
          // Do something here.
          // console.log('froalaEditor.link.beforeInsert');
          // console.log('cmd, param1, param2', cmd, param1, param2);
          // this is the editor instance.
          // console.log(this);
        },
      },
    };
  }

  config = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'], // toggled buttons
        [{ header: 1 }, { header: 2 }],
        ['link', 'image', 'video'], // link and image, video
      ],
    },
  };

  ngOnInit() {
    var self = this;
    this.postService.getPosts; // Call getPosts() before main form is being called
    this.reactivePostForm(); // Call post form when component is ready
    this.panoramicImageOptions = [
      { value: 0, label: 'No' },
      { value: true, label: 'Yes' },
    ];
    this.optionsFeatured = [
      { value: 0, label: 'No' },
      { value: true, label: 'Yes' },
    ];
    this.optionsCategory = [
      { value: 'stream', label: 'Stream' },
      { value: 'essentials', label: 'Essentials' },
    ];
    this.optionsTag = [
      { value: 'art', label: 'Art' },
      { value: 'english', label: 'English' },
      { value: 'music', label: 'Music' },
    ];
    this.optionsVisibility = [
      { value: 'staff', label: 'Staff' },
      { value: 'student', label: 'Student' },
      { value: 'parent', label: 'Parent' },
      { value: 'newly-enrolled', label: 'Newly Enrolled' },
      { value: 'guest', label: 'Guest' },
    ];
    this.getRoute();
    FroalaEditor.POPUP_TEMPLATES['customPlugin.popup'] =
      '[_BUTTONS_][_CUSTOM_LAYER_][_FOOTER_BUTTONS_]';
    // Define popup buttons.
    Object.assign(FroalaEditor.DEFAULTS, {
      popupButtons: ['popupClose'],
      footerButton: ['insertButton'],
    });
    // The custom popup is defined inside a plugin (new or existing).
    FroalaEditor.PLUGINS.customPlugin = function (editor) {
      // Create custom popup.
      function initPopup() {
        // Popup buttons.
        var popup_buttons = '';

        // Create the list of buttons.
        // if (editor.opts.popupButtons.length > 1) {
        popup_buttons += '<div class="fr-buttons fr-tabs">';
        popup_buttons += editor.button.buildList(editor.opts.popupButtons);
        popup_buttons += '</div>';
        // }

        var footerButtons = '';
        footerButtons += '<div class="fr-buttons fr-tabs">';
        footerButtons += editor.button.buildList(editor.opts.footerButton);
        footerButtons += '</div>';

        // Load popup template.
        var template = {
          buttons: popup_buttons,
          custom_layer:
            '<div class="custom-layer" style="width:250px;padding:10px;">' +
            '<div class="fr-input-line" data-children-count="1">' +
            '<input id="fr-image-by-url-layer-text-1" type="text" placeholder="" tabindex="1" aria-required="true" dir="auto" class="">' +
            '<label for="fr-image-by-url-layer-text-1">URL</label>' +
            '</div>' +
            '<div class="fr-input-line" style="display: block;" data-children-count="1">' +
            '<input id="fr-link-insert-layer-text-1" name="text" type="text" class="fr-link-attr" placeholder="" tabindex="2" dir="auto">' +
            '<label for="fr-link-insert-layer-text-1">Text</label>' +
            '</div>' +
            '</div>',
          FOOTER_BUTTONS: footerButtons,
          // FOOTER_BUTTONS: '<div class="fr-action-buttons" style="height: 65px; padding: 10px;">' +
          // '<button #froalaCustomInsert id="froalaCustomInsert" class="fr-command fr-submit" role="button" href="#" tabindex="4" type="button" (click)="self[upload]()">Insert</button>' +
          // '</div>'
        };

        // Create popup.
        var $popup = editor.popups.create('customPlugin.popup', template);

        return $popup;
      }
      // Show the popup
      function showPopup() {
        // Get the popup object defined above.
        var $popup = editor.popups.get('customPlugin.popup');

        // If popup doesn't exist then create it.
        // To improve performance it is best to create the popup when it is first needed
        // and not when the editor is initialized.
        if (!$popup) $popup = initPopup();

        // Set the editor toolbar as the popup's container.
        editor.popups.setContainer('customPlugin.popup', editor.$tb);

        // This will trigger the refresh event assigned to the popup.
        // editor.popups.refresh('customPlugin.popup');

        // This custom popup is opened by pressing a button from the editor's toolbar.
        // Get the button's object in order to place the popup relative to it.
        var $btn = self._elementRef.nativeElement.querySelector(
          '.fr-command[data-cmd="myButton"]'
        );

        // Set the popup's position.
        var left = $btn.offsetLeft + 435 + 150 / 2;
        var top =
          $btn.offsetTop +
          (editor.opts.toolbarBottom ? 10 : $btn.offsetTop + 665 - 10);

        // Show the custom popup.
        // The button's outerHeight is required in case the popup needs to be displayed above it.
        editor.popups.show('customPlugin.popup', left, top, $btn.offsetTop);
      }

      // Hide the custom popup.
      function hidePopup() {
        editor.popups.hide('customPlugin.popup');
      }

      // Insert button
      function insertButton() {
        var link = (<HTMLInputElement>(
          document.getElementById('fr-image-by-url-layer-text-1')
        )).value;
        var text = (<HTMLInputElement>(
          document.getElementById('fr-link-insert-layer-text-1')
        )).value;

        if (link != '' && text != '') {
          let html =
            '<a style="background-color: #3176BC;border: none;color: white;padding: 10px 32px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px;" href="' +
            link +
            '" class="button">' +
            text +
            '</a>';
          editor.html.insert(html);
          // console.log('fdf', editor.position);
        }

        editor.popups.hide('customPlugin.popup');
      }

      // Methods visible outside the plugin.
      return {
        showPopup: showPopup,
        insertButton: insertButton,
        hidePopup: hidePopup,
      };
    };

    FroalaEditor.DefineIcon('myButton', { NAME: 'minus', SVG_KEY: 'minus' });
    FroalaEditor.RegisterCommand('myButton', {
      title: 'Insert Button',
      focus: true,
      plugin: 'customPlugin',
      undo: false,
      refreshAfterCallback: false,

      callback: function () {
        // Saving current selection
        this.selection.save();
        this.customPlugin.showPopup();
      },
    });
    // Define custom popup close button icon and command.
    FroalaEditor.DefineIcon('popupClose', { NAME: 'times', SVG_KEY: 'close' });
    FroalaEditor.RegisterCommand('popupClose', {
      title: 'Close',
      undo: false,
      focus: false,
      callback: function () {
        this.customPlugin.hidePopup();
      },
    });

    FroalaEditor.DefineIcon('insertButton', { NAME: 'plus', SVG_KEY: 'plus' });
    FroalaEditor.RegisterCommand('insertButton', {
      title: 'Insert',
      focus: true,
      undo: false,
      callback: function () {
        // Restoring selection before calling function
        this.selection.restore();
        this.customPlugin.insertButton();
      },
    });

    // this.renderer.listen(this.froalaCustomInsert.nativeElement, 'click', (event) => {
    //   // Do something with 'event'
    //   console.log('dfssfsd', event);
    // })
    this.progressRef = this.progress.ref('myProgress');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.watchFormChanges();
    }, 1000);
  }

  watchFormChanges() {
    this.postForm.valueChanges.pipe(auditTime(2000)).subscribe((formData) => {
      if (this.postForm.valid) {
        // this.submitPostData();
      }
    });
  }

  autoSave() {
    // this.submitPostData();
  }

  initialize(initControls) {
    this.froalaInitControls = initControls;
    // Initialising editor
    this.froalaInitControls.initialize();
  }

  async getRoute() {
    await this.route.url.subscribe((url) => {
      this.url = url[0].path;
    });

    if (this.url == 'edit-post') {
      await this.getParams();
      this.getPostDetails();
    }
  }

  getParams() {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.postId = queryParams.get('id');
    });
  }

  private getPosts() {
    this.postService.getPosts().subscribe((data) => {
      this.posts = data.map((e) => {
        return {
          id: e.payload.doc.id,
          ...(e.payload.doc.data() as Post),
        };
      });

      this.addPostsAsLinks();
    });
  }

  private addPostsAsLinks() {
    const tmp = [];
    this.posts.forEach((post) => {
      tmp.push({
        text: post.title,
        href: '/detail/' + post.id,
        target: '_self',
        rel: 'nofollow',
      });
    });

    const existingLinkList = this.froalaInitControls.getEditor().opts.linkList;
    this.froalaInitControls.getEditor().opts.linkList = [
      ...existingLinkList,
      ...tmp,
    ];
  }

  getPostDetails() {
    this.postService.getPost(this.postId).subscribe((response) => {
      // let post: any = response.payload.data();
      this.existingPostData = response.payload.data();
      this.postForm.setValue({
        title: this.existingPostData.title,
        subTitle: this.existingPostData.subTitle,
        image: this.existingPostData.image,
        thumbnail: this.existingPostData.thumbnail ? this.existingPostData.thumbnail: null,
        panoramicImage: this.existingPostData.panoramicImage ? this.existingPostData.panoramicImage : 0,
        content: this.existingPostData.content,
        featured: this.existingPostData.featured ? this.existingPostData.featured : 0,
        tags: this.existingPostData.tags,
        category: this.existingPostData.category,
        status: this.existingPostData.status,
        visibility: this.existingPostData.visibility,
        dateCreated: this.existingPostData.dateCreated,
        dateUpdated: this.existingPostData.dateUpdated,
      });
    });
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.upload();
  }

  showFiles(reset?: boolean) {
    let files = '';
    if (reset) {
      files = 'fdfdfdfdf';
    } else {
      for (let i = 0; i < this.files.length; i++) {
        files = this.files[i].name;
      }
    }
    return files;
  }

  async onUploadOutput(output: UploadOutput | any) {
    if (output.file) {
      this.selectedFiles = output.file.nativeFile;

      await this.resizeImage();
      this.upload();
    }
    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex((file) => file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
    this.showFiles();
  }

  contentFileUpload(file: object): Promise<boolean> {
    this.progressRef.start();
    return new Promise<boolean>((resolve, reject) => {
      this.uploadService.uploadProgress.subscribe((progress) => {
        this.zone.run(() => {
          this.progressRef.set(progress);
        });
      });
      this.uploadService.uploadFile(file).then((response: any) => {
        this.toaster.success('Successfully uploaded.');
        this.progressRef.complete();
        resolve(response.Location);
      });
    });
  }

  private upload(thumbnail = false): Promise<boolean> {
    return new Promise((resolve) => {
      this.progressRef.start();
      // const file = this.selectedFiles.item(0);
      const file = thumbnail ? this.thumbnailImage : this.selectedFiles;

      this.uploadService.uploadProgress.subscribe((progress) => {
        this.zone.run(() => {
          this.progressRef.set(progress);
        });
      });

      this.uploadService.uploadFile(file).then((response: any) => {
        if (thumbnail) {
          this.postForm.patchValue({ thumbnail: response.Location });
        } else {
          this.postForm.patchValue({ image: response.Location });
        }

        this.toaster.success('Successfully uploaded.');
        this.progressRef.complete();
        this.showFiles(true);

        resolve(true);
      });
    });
  }

  // Reactive post form
  reactivePostForm() {
    this.postForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(2)]],
      subTitle: [''],
      image: ['', [Validators.required]],
      thumbnail: [''],
      panoramicImage: [0, Validators.required],
      // 17 words. ie, 10 words plus 7 words consists of html tag(<p> and </p>)
      content: ['', [Validators.required, Validators.minLength(17)]],
      featured: [0, Validators.required],
      category: ['stream', Validators.required],
      tags: [''],
      status: ['active', Validators.required],
      visibility: ['staff'],
      dateCreated: [''],
      dateUpdated: [''],
    });

    setTimeout(() => {
      this.postForm.get('visibility').valueChanges.subscribe((value) => {
        this.updatedVisibility = value;
      });
    }, 2000);
  }

  // Accessing form control using getters
  get title() {
    return this.postForm.get('title');
  }

  get subTitle() {
    return this.postForm.get('subTitle');
  }

  get image() {
    return this.postForm.get('image');
  }

  get thumbnail() {
    return this.postForm.get('thumbnail');
  }

  get content() {
    return this.postForm.get('content');
  }

  get featured() {
    return this.postForm.get('featured');
  }

  get category() {
    return this.postForm.get('category');
  }

  get tags() {
    return this.postForm.get('tags');
  }

  get status() {
    return this.postForm.get('status');
  }

  get visibility() {
    return this.postForm.get('visibility');
  }

  // Reset form's values
  resetForm() {
    this.postForm.reset();
  }

  submitPostData() {
    let postData = this.postForm.value;
    if (postData.featured == 0) {
      // Converting to boolean
      postData.featured = false;
    }
    if (this.url == 'add-post') {
      postData.status = 'active';
      postData.dateCreated = new Date();
      postData.dateUpdated = new Date();

      this.postService.createPost(postData); // Submit post data using CRUD API
      this.toaster.success(postData.title + ' successfully added!'); // Show success message when data is successfully submitted
      this.resetForm(); // Reset form when clicked on reset button
    } else {
      postData.dateUpdated = new Date();

      const visibilityTouched = this.postForm.controls.visibility.touched;

      if (!visibilityTouched && this.postForm.value.visibility.length === 0) {
        // Visibility is not touched
        // Set visibility to existing post value
        postData.visibility = this.existingPostData.visibility;
      } else {
        if (this.updatedVisibility) {
          // Visibility touched and updated
          postData.visibility = this.updatedVisibility;
        } else {
          // Visibility is touched but no changes made
          postData.visibility = this.existingPostData.visibility;
        }
      }

      this.postService.updatePost(this.postId, postData);
      this.toaster.success(postData.title + ' successfully updated!'); // Show success message when data is successfully updated
      // setTimeout(() => {
      //   this.router.navigate(['/view-posts']);
      // }, 1000);
    }
  }

  categoryChanged(e) {
    if (e.value == 'essentials') {
      this.featuredDisabled = true;
    } else {
      this.featuredDisabled = false;
    }
  }

  private dataURLtoFile(dataUrl, filename) {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

  private resizeImage() {
    try {
      return new Promise(resolve => {
      let resizedImage;
      // Read in file
      const file: any = this.selectedFiles;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent) {
          const image: any = new Image();
          image.onload = function (imageEvent) {
            // Resize the image
            var canvas = document.createElement('canvas'),
              max_size = 400,
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            resizedImage = canvas.toDataURL('image/jpeg');
            // document.getElementById('thumbnail-preview')['src'] = resizedImage;
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }

      // @TODO
      // Remove timeout and add async
      setTimeout(async () => {
        this.thumbnailImage = resizedImage;

        this.thumbnailImage = this.dataURLtoFile(this.thumbnailImage, (this.selectedFiles['name'].replace(/\.[^/.]+$/, "") + '-thumbnail.png'));
        await this.upload(true);

        resolve('true');
      }, 2500);
    });
    } catch (error) {
      console.error('Error in resizing image', error);
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from '../app/components/sign-in/sign-in.component';
import { DashboardComponent } from '../app/components/dashboard/dashboard.component';
import { NotificationListComponent } from '../app/components/notification-list/notification-list.component';
import { NotificationComponent } from '../app/components/notification/notification.component';
import { AddPostComponent } from '../app/components/add-post/add-post.component';
import { PostListComponent } from '../app/components/post-list/post-list.component';
import { PageNotFoundComponent } from '../app/components/page-not-found/page-not-found.component';
import { ScanListComponent } from '../app/components/scan-list/scan-list.component';
import { WhoIsInComponent } from '../app/components/who-is-in/who-is-in.component';
import { ReRegistrationListComponent } from '../app/components/re-registration-list/re-registration-list.component';
import { AnalyticsComponent } from '../app/components/analytics/analytics.component';

// Importing canActivate guard services
import { AuthGuard } from "../app/guards/auth/auth.guard";
import { SecureInnerPagesGuard } from "../app/guards/secure-inner-pages/secure-inner-pages.guard";

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'view-notifications', component: NotificationListComponent, canActivate: [AuthGuard]},
  { path: 'notification', component: NotificationComponent, canActivate: [AuthGuard] },
  { path: 'notification/:id', component: NotificationComponent, canActivate: [AuthGuard] },
  { path: 'add-post', component: AddPostComponent, canActivate: [AuthGuard] },
  { path: 'edit-post', component: AddPostComponent, canActivate: [AuthGuard] },
  { path: 'view-posts', component: PostListComponent, canActivate: [AuthGuard] },
  { path: 'view-scans', component: ScanListComponent, canActivate: [AuthGuard]},
  { path: 'who-is-in', component: WhoIsInComponent, canActivate: [AuthGuard]},
  { path: 're-registrations', component: ReRegistrationListComponent, canActivate: [AuthGuard]},
  { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard]},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { Post } from '../../models/post/post.model';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private firestore: AngularFirestore) { }

  getPost(id) {
    return this.firestore.collection('posts').doc(id).snapshotChanges();
  }

  getPosts() {
    return this.firestore.collection('posts', ref => ref.orderBy('visibility', 'desc')).snapshotChanges();
  }

  createPost(post: Post){
    return this.firestore.collection('posts').add(post);
  }

  updatePost(id, post: Post){
    this.firestore.collection('posts').doc(id).update(post);
    // this.firestore.collection('posts').doc(id).set(post, { merge: true });
    // delete post.id;
    // this.firestore.doc('policies/' + id).update(post);
  }

  deletePost(postId: string){
    this.firestore.doc('posts/' + postId).delete();
  }

}

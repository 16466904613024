import { Pipe, PipeTransform } from '@angular/core';

import { Isams } from '../models/isams/isams.model';
import { IsamsService } from '../services/isams/isams.service';
import { Subject } from 'rxjs';

@Pipe({
  name: 'findInIsams'
})
export class FindInIsamsPipe implements PipeTransform {

  private isamsData = new Subject<Isams[]>();
  private isamsDataArray;
  
  constructor(
    private isamsService: IsamsService
  ) { }

  transform(value: any, args: any): any {
    return this.findInIsams(value, args);
  }

  getIsamsData() {
    this.isamsService.getIsamsSync().subscribe(data => {
      this.isamsData.next(data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Isams
        }
      }));
    });
  }

  public findInIsams(email: string, parameter: string) {
    console.log('Hi from find');
    if (this.isamsDataArray) {
      const match: any = this.isamsDataArray.find(element => element.schoolEmailAddress == email);
      // console.log(match);
      if (match) {
        if (parameter == 'name') return match.preferredName + ' ' + match.surname;
      } else {
        return 'NA';
      }
    }
  }

}

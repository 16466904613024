import { Component, OnInit } from '@angular/core';

import { AuthService } from "../../services/auth/auth.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isLoggedIn: boolean = false;
  currentUser: object;
  public userRole: number;
  public selectedNavigationOption: string;
  public navigationOptions: Array<any>;
  public isamsSyncTime: any;

  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.isLoggedIn = this.authService.isLoggedIn;
    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit() {
    this.navigationOptions = [
      { value: 'analytics', label: 'Analytics' },
      { value: '', label: 'Posts', group: true },
      { value: 'view-posts', label: 'View' },
      { value: 'add-post', label: 'Add' },
      { value: '', label: 'Notifications', group: true },
      { value: 'view-notifications', label: 'View' },
      { value: 'notification', label: 'Send' },
      { value: 're-registrations', label: 'Re-registration' },
      { value: 'view-scans', label: 'Sign in/out' },
    ];

    this.activatedRoute.url.subscribe(r => this.selectedNavigationOption = r[0].path);

    const now = new Date();
    now.setHours(now.getHours() - 1);
    this.isamsSyncTime = now;
  }

  public changeNavigation(event: any) {
    console.log(event);
    // if (event.value != this.selectedNavigationOption) {
      // this.selectedNavigationOption = event.label;
      this.router.navigateByUrl(event.value);
    // }
  }
}

import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import 'firebase/functions';
import { credentials } from '../../credentials';
if (!firebase.apps.length) {
  firebase.initializeApp(credentials.firebaseConfig);
}
const functions = firebase.app().functions('europe-west2');

@Injectable({
  providedIn: 'root'
})
export class GsheetService {

  constructor() { }

  getAutomatedNotifications() {
    return new Promise(function (resolve, reject) {
      var rows = functions.httpsCallable('getAutomatedNotifications');
      rows({}).then((result) => {
        resolve(result.data.response);
      })
        .catch((error) => {
          console.error('Error in getting notifications', error);
          reject('Error in getting notifications: ' + error);
        });
    });
  }

  updateNotificationStatus(notation: number) {
    return new Promise(function (resolve, reject) {
      var rows = functions.httpsCallable('updateNotificationStatus');
      rows({'notation': notation}).then((result) => {
        resolve(result.data.response);
      })
        .catch((error) => {
          reject('Error in updating notification status: ' + error);
        });
    });
  }  
}

<app-navigation></app-navigation>
<div class="container-fluid">
  <div class="row mb-3">
    <div class="col-auto">
      <div class="change-month dropdown" mdbDropdown>
        <button mdbDropdownToggle mdbBtn class="dropdown-toggle waves-light mr-2 n27-button-primary" type="button"
          mdbWavesEffect>
          Change Year
        </button>
      
        <div class="dropdown-menu dropdown-primary">
          <a class="dropdown-item" *ngFor="let year of years; let i = index" (click)="switchLog(i, null)">{{ year }}</a>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="change-month dropdown" mdbDropdown>
        <button mdbDropdownToggle mdbBtn class="dropdown-toggle waves-light mr-2 n27-button-primary" type="button"
          mdbWavesEffect>
          Change Month
        </button>
      
        <div class="dropdown-menu dropdown-primary">
          <a class="dropdown-item" *ngFor="let month of visibleMonths; let i = index" (click)="switchLog(null, i)">{{ month }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card card-body">
        <div class="card-title">
          <h1 class="h2">Sign in/out Log - {{ visibleMonths[selectedMonth] }}&nbsp;{{ selectedYear }}</h1>
        </div>

        <div class="row">
          <div class="col">
            <table mdbTable striped="true" hover="true">
              <thead>
                <tr class="text-bold">
                  <th aria-controls="tableSortExample" [mdbTableSort]="scansDataArray" sortBy="dateCreated" scope="col">
                    Date
                    <mdb-icon fas icon="sort"></mdb-icon>
                  </th>
                  <th scope="col">Time</th>
                  <th scope="col">Name</th>
                  <th scope="col">Late</th>
                  <th scope="col">Email</th>
                  <th scope="col">Type</th>
                  <th scope="col">Method</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="(scans | async)?.length === 0">
                  <td colspan="8" class="text-center">No data found, please change year and/or month.</td>
                </tr>
                <tr mdbTableCol *ngFor="let scan of scans | async">
                  <td>{{ scan.dateCreated?.toDate() | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ scan.dateCreated?.toDate() | date: 'HH:mm:ss' }}</td>
                  <td class="text-capitalize">{{ scan.name }}</td>
                  <td *ngIf="scan.lateness == true" class="text-capitalize text-danger">yes</td>
                  <td *ngIf="scan.lateness == false" class="text-capitalize text-success">no</td>
                  <td *ngIf="scan.lateness == 'na'">
                    <i class="fas fa-minus"></i>
                  </td>
                  <td>{{ scan.email }}</td>
                  <td class="text-capitalize">{{ scan.userType }}</td>
                  <td class="text-uppercase">{{ scan.type }}</td>
                  <td class="text-capitalize">{{ scan.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--<div class="row">
          <div class="col-md-12">
            <table class="table table-striped table-hover">
              <thead>
                <tr class="text-bold">
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Type</th>
                  <th scope="col">Method</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let scan of scans | async">
                  <td>{{ scan.dateCreated?.toDate() | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ scan.dateCreated?.toDate() | date: 'HH:mm:ss' }}</td>
                  <td class="text-capitalize">{{ scan.name }}</td>
                  <td>{{ scan.email }}</td>
                  <td class="text-capitalize">{{ scan.userType }}</td>
                  <td class="text-uppercase">{{ scan.type }}</td>
                  <td class="text-capitalize">{{ scan.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
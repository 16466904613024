import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../../services/notification/notification.service';
import { Notification } from '../../interfaces/notification';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

  public notifications = new Subject<Notification[]>();
  public currentUser: object;

  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

  ngOnInit() {
    this.notificationService.getNotifications().subscribe(data => {
      this.notifications.next(data.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as Notification
        }
      }));
    });
  }

  duplicate(id: string) {
    this.router.navigate(['notification', id]);
  }

  delete(id: string) {
    this.notificationService.deleteNotification(id);
  }

}

<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-6">
      <br>
      <br>
        <mdb-card class="testimonial-card">
          <div class="card-up indigo lighten-1">
            <h3 class="white-text text-center pt-3">Welcome to North27 School Application</h3>
          </div>
          <div class="avatar mx-auto">
            <img src="/assets/n27/logo-small.svg" class="rounded-circle">
          </div>
          <div class="card-body">
            <div id="login" style="display:block;">
              <div class="btn-group mb-3" style="width:100%;">
                <a class="btn btn-danger pb-0 waves-effect waves-light">
                  <i class="fab fa-google fa-2x"></i>
                </a>
                <a class="btn btn-danger waves-effect waves-light" style="width:100%;"
                  (click)="authService.GoogleAuth()">Sign in with Google G Suite</a>
              </div>
            </div>
            <p></p>
          </div>
        </mdb-card>
    </div>
  </div>
</div>
<!-- Calling GoogleAuth Api from AuthService -->
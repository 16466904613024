import { Component, OnInit, ElementRef, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';

import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { ReRegistrationService } from '../../services/re-registrations/re-registration.service';
import { IsamsService } from '../../services/isams/isams.service';
import { DevicesService } from '../../services/devices/devices.service';

@Component({
  selector: 'app-re-registration-list',
  templateUrl: './re-registration-list.component.html',
  styleUrls: ['./re-registration-list.component.scss']
})
export class ReRegistrationListComponent implements OnInit {

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;

  elements: any = [];
  // headElements = ['id','Student', 'Grade', 'Re-reg Status', 'Parent', 'Date'];

  searchText: string = '';
  previous: string;

  maxVisibleItems: number = 200;

  private studentsObservable;
  private parentsObservable;
  private reRegistrationsObservable;
  private devicesObservable;

  private students: Array<any>;
  private parents: Array<any>;
  private reRegistrations: Array<any>;
  private devices: Array<any>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private reRegistrationService: ReRegistrationService,
    private isamsService: IsamsService,
    private devicesService: DevicesService
  ) { }

  @HostListener('input') oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.getStudents();
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.cdRef.detectChanges();
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      // this.elements = this.mdbTable.searchLocalDataByFields(this.searchText, ['forename', 'surname', 'yearGroup', 'reRegistration.children.status']);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  private getStudents() {
    this.studentsObservable = this.isamsService.getIsamsSyncStudents().subscribe((response) => {
      this.students = response.map(e => {
        return {
          id: e.payload.doc.id,
          parentDetails: [],
          reRegistration: [],
          // "as {}" is added for fixing TS generic type spread limitation
          // https://github.com/Microsoft/TypeScript/issues/10727 
          ...e.payload.doc.data() as {}
        }
      });
      this.getParents();
    });
  }

  private getParents() {
    this.parentsObservable = this.isamsService.getIsamsSyncParents().subscribe((response) => {
      this.parents = response.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as {}
        }
      });
      this.getReRegistrations();
    });
  }

  private getReRegistrations() {
    this.reRegistrationsObservable = this.reRegistrationService.getReRegistrations().subscribe((response) => {
      this.reRegistrations = response.map(e => {
        return {
          id: e.payload.doc.id,
          ...e.payload.doc.data() as {}
        }
      });
      this.getDevices();
    });
  }

  private getDevices() {
    this.devicesObservable = this.devicesService.getDevices().subscribe((response) => {
      this.devices = response.map(e => {
        const user = e.payload.doc.get('user');
        return {
          // id: e.payload.doc.id,
          id: (user) ? user.email : null,
          ...e.payload.doc.data() as {}
        }
      });
      this.populateReRegistrations();
    });
  }

  private populateReRegistrations() {
    let i = 1;
    this.students.forEach((student, ix) => {
      // Skipping Grade 12
      if (student.yearGroup < 12) {
        student.id = i;
        i++;
        student.parents.forEach(parent => {
          // Checking multiple emails
          if (parent.emailAddress.indexOf(';') > -1) {
            // Emails
            const emails = parent.emailAddress.split(';');
            for (const email of emails) {
              const reRegisterCheck = this.reRegistrations.find(o => o.id == email);
              if (reRegisterCheck) {
                student.reRegistration.push(reRegisterCheck);
              }

              // Adding parent details
              let tmp = this.parents.find(o => o.emailAddress == email);
              if (!tmp) {
                tmp = { emailAddress: email };
              }
              // Adding device details
              tmp.deviceDetails = this.devices.find(d => d.user.email == email);
              if (tmp.deviceDetails) {
                tmp.deviceDetails.daysSinceLastLogin = this.numDaysBetween(tmp.deviceDetails.dateCreated.toDate());
              }

              student.parentDetails.push(tmp);
            }
          } else {
            const reRegisterCheck = this.reRegistrations.find(o => o.id == parent.emailAddress);
            if (reRegisterCheck) {
              student.reRegistration.push(reRegisterCheck);
            }

            // Adding parent details
            let tmp = this.parents.find(o => o.emailAddress == parent.emailAddress);
            if (!tmp) {
              tmp = { emailAddress: parent.emailAddress };
            }

            // Adding device details
            
            tmp.deviceDetails = this.devices.find(d => d.user.email == parent.emailAddress);
            if (tmp.deviceDetails) {
              tmp.deviceDetails.daysSinceLastLogin = this.numDaysBetween(tmp.deviceDetails.dateCreated.toDate());
            }

            student.parentDetails.push(tmp);
          }
        });
        this.elements.push(student);
      }
    });

    this.mdbTable.setDataSource(this.elements);
    this.elements = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();

    this.studentsObservable.unsubscribe();
    this.parentsObservable.unsubscribe();
    this.reRegistrationsObservable.unsubscribe();
    this.devicesObservable.unsubscribe();
  }

  private numDaysBetween(d: Date) {
    const d1 = new Date(d);
    // Today
    const d2 = new Date();
    var diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
  };

}

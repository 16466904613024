import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-isams-year-groups',
  templateUrl: './isams-year-groups.component.html',
  styleUrls: ['./isams-year-groups.component.scss']
})
export class IsamsYearGroupsComponent implements OnInit {

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class YearGroupsService {
  constructor(private firestore: AngularFirestore) {}

  getAll() {
    try {
      return this.firestore
        .collection('isamsYearGroups', (ref) =>
          ref.orderBy('dateCreated', 'desc')
        )
        .snapshotChanges();
    } catch (error) {
      console.error('Error in getting all year groups', error);
    }
  }

  getByYearGroup(YearGroup: string) {
    try {
      return this.firestore
        .collection('isamsSync', (ref) =>
          ref.where('yearGroup', '==', YearGroup)
        )
        .snapshotChanges();
    } catch (error) {
      console.error('Error in getting all year groups', error);
    }
  }
}

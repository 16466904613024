export const credentials = {
    firebaseConfig: {
        apiKey: "AIzaSyApCiwwHiN5K6g7mjCi-YzrlEHhVY6kKsc",
        authDomain: "school-app-27022019.firebaseapp.com",
        databaseURL: "https://school-app-27022019.firebaseio.com",
        projectId: "school-app-27022019",
        storageBucket: "school-app-27022019.appspot.com",
        messagingSenderId: "845468457389"
    },
    isamsConfig: {
        host: "https://halcyonschool.isams.cloud",
        endPoint: "/auth/connect/token",
        clientId: "halcyon",
        clientSecret: "FF266F4F-D525-412F-BE21-B4D2441C466B",
        grantType: "client_credentials",
        scope: "restapi"
    }
};
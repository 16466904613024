<app-navigation></app-navigation>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="card card-body">
        <div class="card-title">
          <h1 class="h2">Posts</h1>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 mx-auto">
              <div class="md-form">
                <input type="text" class="form-control" [(ngModel)]="searchText" (keyup)="searchItems()" id="search-input"
                      mdbInput>
                <label for="search-input">Search</label>
              </div>
            </div>
            <table mdbTable #tableEl="mdbTable" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
              <thead class="sticky-top">
              <tr>
                <th *ngFor="let head of headElements; let i = index" [mdbTableSort]="elements" [sortBy]="headElements[i]"
                    scope="col">{{ head | titlecase }} <mdb-icon fas icon="sort"></mdb-icon>
                </th>
              </tr>
              </thead>
              <tbody #row>
              <tr mdbTableCol (rowCreated)="onRowCreate($event)" (rowRemoved)="onRowRemove($event)" *ngFor="let el of elements; let i = index">
                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row">{{ el.title }}</td>
                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{ (el?.subTitle) ? (el.subTitle?.length > 25) ? (el.subTitle | slice:0:25) + '...' : (el.subTitle) : 'NA' }}</td>
                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" [innerHTML]="truncateHTML(el.content)"></td>
                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{ el.dateUpdated?.toDate() | date: 'medium' }}</td>
                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" class="text-center">
                  <i *ngIf="el.featured" class="fas fa-check-circle fa-lg c-green"></i>
                  <i *ngIf="!el.featured" class="far fa-times-circle fa-lg c-red"></i>
                </td>
                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{ el.visibility }}</td>
                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
                  <div aria-label="Actions">
                    <button mdbTooltip="Update" placement="top" [routerLink]="['/edit-post']" [queryParams]="{ id: el.id }" mdbBtn type="button" size="sm" [ngClass]="{'disabled': currentUser.role === 0}" class="waves-light mr-3 n27-button-primary" mdbWavesEffect>Update</button>
                    <a mdbTooltip="Delete" placement="top" [ngClass]="{'disabled': currentUser.role === 0}" (click)="delete(el.id)">
                      <i class="far fa-trash-alt fa-lg c-red"></i>
                    </a>
                </div>
                </td>
              </tr>
              </tbody>
              <tfoot class="grey lighten-5 w-100">
              <tr>
                <td colspan="7">
                  <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="elements"></mdb-table-pagination>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <table class="table table-striped table-hover">
              <thead>
                <tr class="text-bold text-center">
                  <th scope="col">Title</th>
                  <th scope="col">SubTitle</th>
                  <th scope="col">Content</th>
                  <th scope="col">Date Updated</th>
                  <th scope="col">Featured</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let post of posts">
                  <td>{{ post.title }}</td>
                  <td>{{ (post.subTitle) ? (post.subTitle?.length > 25) ? (post.subTitle | slice:0:25) + '...' : (post.subTitle) : 'NA' }}</td>
                  <td [innerHTML]="truncateHTML(post.content)"></td>
                  <td>{{ post.dateUpdated?.toDate() | date: 'medium' }}</td>
                  <td>
                    <i *ngIf="post.featured" class="fas fa-check-circle fa-lg c-green"></i>
                    <i *ngIf="!post.featured" class="far fa-times-circle fa-lg c-red"></i>
                  <td>
                      <div aria-label="Actions">
                          <button mdbTooltip="Update" placement="top" [routerLink]="['/edit-post']" [queryParams]="{ id: post.id }" mdbBtn type="button" size="sm" class="waves-light mr-3 n27-button-primary" mdbWavesEffect>Update</button>
                          <a mdbTooltip="Delete" placement="top" (click)="delete(post.id)">
                            <i class="far fa-trash-alt fa-lg c-red"></i>
                          </a>
                      </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<app-navigation></app-navigation>
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-6">
      <div class="card card-body">
        <div class="card-title">
          <h1 class="h2">{{ (url == 'add-post') ? 'Add Post' : 'Update Post' }}</h1>
          <hr>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- Post form -->
            <form [formGroup]="postForm" novalidate>
              <div class="md-form">
                <input mdbInput type="text" id="title" formControlName="title" class="form-control" required>
                <label for="title">Title</label>
                <p *ngIf="title.touched && title.invalid">
                  <mdb-error class="error">Please enter a title</mdb-error>
                </p>
                <mdb-error *ngIf="title.errors?.minlength" class="error">Title shouldn't be less than 2 words
                </mdb-error>
              </div>
              <div class="md-form">
                <input mdbInput type="text" id="subTitle" formControlName="subTitle" class="form-control">
                <label for="subTitle">Subtitle</label>
              </div>
              <div class="row mt-25 pd-0">
                <div class="col-10">
                  <div class="file-field md-form">
                      <div mdbBtn floating="true" size="md" color="amber" mdbWavesEffect>
                        <i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
                        <input type="file" mdbFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                      </div>
                    <div class="file-path-wrapper mt-3">
                      <input class="file-path" type="text" placeholder="Upload your file" [value]="showFiles()">
                    </div>
                  </div>
                  <p *ngIf="content.touched && image.invalid">
                      <mdb-error class="error">Please add an image</mdb-error>
                    </p>
                  </div>
                  
                    <div *ngIf="image.valid" class="col-2">
                      <img class="img-fluid img-thumbnail" [src]="image.value">
                    </div>

                <!-- <div class="col-10">
                <label class="btn btn-default">
                  <input type="file" (change)="selectFile($event)">
                </label>
                <p *ngIf="content.touched && image.invalid">
                  <mdb-error class="error">Please add an image</mdb-error>
                </p>
                <button class="btn btn-success" [disabled]="!selectedFiles" (click)="upload()">Upload</button>
                </div>
                <div *ngIf="image.valid" class="col-2">
                  <img class="img-fluid img-thumbnail" [src]="image.value">
                </div> -->
              </div>

              <div class="col-md-12 mt-25 pd-0">
                <label>
                  <strong>Panoramic Image</strong>
                </label>
                <p class="note note-info">
                  Panoramic or 360 image is an image that captures a complete spherical view of the surroundings. When set to true users can tap the arrows or double-tap the image in the direction they want to view/go or pinch on the screen to zoom in Halcyon App.
                </p>
                <mdb-select [disabled]="!image.valid" [options]="panoramicImageOptions" formControlName="panoramicImage"></mdb-select>
              </div>

              <div class="col-md-12 mt-25 pd-0">
                <label>
                  <strong>Content</strong>
                </label>
                <p *ngIf="content.touched && content.invalid">
                  <mdb-error class="error">Please add content</mdb-error>
                </p>
                <p *ngIf="content.errors?.minlength">
                  <mdb-error class="error">Content shouldn't be less than 10 words</mdb-error>
                </p>
                <textarea [froalaEditor]="froalaOptions" formControlName="content" (froalaInit)="initialize($event)"></textarea>
                <!-- <div [froalaEditor]="froalaOptions"></div> -->
                <!-- <quill-editor placeholder="Enter content" [styles]="{height: '250px'}" -->
                  <!-- [modules]="config" [required]="true" formControlName="content"></quill-editor> -->
                <!-- <pre>[(ngModel)]="htmlText"{{htmlText}}</pre> -->
              </div>

              <div class="col-md-12 mt-25 pd-0">
                <label>
                  <strong>Location</strong>
                </label>
                <p class="note note-info">
                  Location this should appear in Halcyon App.
                </p>
                <mdb-select [options]="optionsCategory" formControlName="category" (selected)="categoryChanged($event)"></mdb-select>
              </div>

              <div class="col-md-12 mt-25 pd-0">
                <label>
                  <strong>Visibility</strong>
                </label>
                <p class="note note-info">
                  Who should see this in Halcyon App.
                </p>
                <mdb-select [options]="optionsVisibility" [multiple]="true" formControlName="visibility" placeholder="Not visible"></mdb-select>
              </div>

              <div class="col-md-12 mt-25 pd-0" [ngClass]="{'disabled': featuredDisabled}">
                <label>
                  <strong>Featured</strong>
                </label>
                <p class="note note-info">
                  If set to true this will be displayed at top of it's location in Halcyon App.
                </p>
                <mdb-select [ngClass]="{'disabled': featuredDisabled}" [options]="optionsFeatured" formControlName="featured"></mdb-select>
              </div>

              <div class="col-md-12 mt-25 pd-0">
                <label>
                  <strong>Tag(s)</strong>
                </label>
                <mdb-select [options]="optionsTag" [multiple]="true" formControlName="tags" placeholder="No tags"></mdb-select>
              </div>             

              <div class="form-group text-right">
                <button type="button" class="btn btn-secondary gap-right" (click)="resetForm()">Reset</button>
                <button type="submit" class="btn btn-success" [disabled]="!postForm.valid"
                  (click)="submitPostData()">{{ (url == 'add-post') ? 'Add Post' : 'Update Post' }}</button>
              </div>
            </form> <!-- Form ends-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
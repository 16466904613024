import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import 'firebase/functions';
import { credentials } from '../../credentials';
firebase.initializeApp(credentials.firebaseConfig);
const functions = firebase.app().functions('europe-west2');

@Injectable({
  providedIn: 'root'
})
export class GsuiteService {

  constructor() { }

  getGroups(nextPageToken: string) {
    return new Promise(function (resolve, reject) {
      var groups = functions.httpsCallable('getGroups');
      let params = {
          nextPageToken: (nextPageToken) ? (nextPageToken) : null
        };

      groups(params).then((result) => {
        resolve(result.data.response);
      })
        .catch((error) => {
          console.error('Error in getting groups', error);
          reject('Error in getting groups: ' + error);
        });
    });
  }
  
  getGroupMembers(groupId: string) {
    return new Promise(function (resolve, reject) {
      var groupMembers = functions.httpsCallable('getGroupMembers');
      groupMembers({"groupId": groupId}).then((result) => {
        resolve(result.data.response);
      })
        .catch((error) => {
          console.error('Error in getting group members', error);
          reject('Error in getting group members: ' + error);
        });
    });
  }

  getSearchUsers(parameter: string) {
    return new Promise(function (resolve, reject) {
      var groupMembers = functions.httpsCallable('getSearchUsers');
      groupMembers({"parameter": parameter}).then((result) => {
        resolve(result.data.response);
      })
        .catch((error) => {
          console.error('Error in getting search users', error);
          reject('Error in getting search users: ' + error);
        });
    });
  }
}
